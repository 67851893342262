import styled from "styled-components";
import { media } from 'assets/static';

export const TabsRow = styled.section`
    flex-wrap: nowrap;
    display: flex;
    margin-left: 1.4vw;
    align-items: flex-start;
    gap: 1rem;
    overflow-y: hidden;
    overflow-x: auto;
    white-space: nowrap;
`

export const TabsLabel = styled.div`
    padding: 1.6vh 1.3vw;
    background-color: #FAFAF9;
    box-shadow: 3px 5px 15px rgb(88 158 195 / 15%);
    border-radius: 12px;

    font-family: 'Libre Franklin', Arial, Helvetica, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: ${props=> props.theme.heading3.fontSize};
    line-height: 1rem;

    white-space: nowrap;

    color: ${props => props.theme.colors.text.generalText};

    & p {
        margin: 4px;
    }

    & p > a {
        text-decoration: none;
        color: inherit;

        font-family: 'Libre Franklin', Arial, Helvetica, sans-serif;
        font-style: normal;
    }
`