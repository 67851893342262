import styled from "styled-components";
import { media, theme } from "assets/static";
import { BoxedBtn } from "components";

export const TableWrapper = styled.div`
    box-sizing: border-box;
    width: 90%;
    height: 100%;
    margin: 1.5% auto 0;
    overflow-x: auto;
`


export const Table = styled.table`
    width: 100%;
    margin: 20px 0px 50px;
    font-family: 'Libre Franklin', Arial, sans-serif;
    font-style: normal;
    overflow: hidden;
    
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
    border-collapse: collapse;
    
    & td {
        border: 0.8px solid ${props=> props.theme.colors.lightBlue};
    }

    & #investors {
        font-size: ${props=> props.theme.heading5.fontSize};
    }
`

export const Thead = styled.thead`
    & tr {
        background-color: ${props=> props.theme.colors.text.generalText};
        color: ${props=> props.theme.colors.text.whiteText};
        text-align: left;
    }
    & tr > th {
        padding: 0 1.1vw;
        font-weight: normal;
        white-space: nowrap;
    }
`


export const Tbody = styled.tbody`
    & tr {
        text-align: left;
        color: ${props=> props.theme.colors.text.generalText};
        
    }
    & tr > td {
        padding: 1.2vh 1.1vw;
        font-weight: normal;
    }

    & tr:nth-of-type(even) {
        background-color: #E9ECF1;
    }
`

export const ThDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    & span:last-child {
        display: flex; 
        flex-direction: column; 
        height: 3vh;
    }
`

export const TdDiv = styled.div`
    width:100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`

export const ViewResultButton = styled(BoxedBtn)`
    border-radius: 5px;
    width: 100%;
    border: 2px solid #4A669790;

    &:hover {
        cursor: pointer;
        color: #FFFFFF;
        background-color: ${theme.colors.text.clickableText};
    }
`