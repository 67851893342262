import React from "react";
import { FooterContainer, FooterHeading, FooterLink, FooterLinkSeparator, LinkContainer } from "../Default/style";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Logo from "../../assets/image/gaa-logo.png";
import { LINK, LINK_M } from '../../services//Constants/constant';


const Footer = (props) =>{
        return (
            <FooterContainer 
                relative={props.relative}
                showSingleLine={props.showSingleLine}>
                {/* Footer: Company & copyright information */}
                <FooterHeading>
                    <LazyLoadImage id="org-logo" src={Logo} alt="Logo" />
                    GLOBAL ACTION ALLIANCE&nbsp;&nbsp;|&nbsp;&nbsp;Powered by WISE-R &#8480; a Global Action Alliance Platform
                </FooterHeading>
                {
                    !props.showSingleLine 
                    &&
                    <LinkContainer>
                        <FooterLink target="_blank" rel="noreferrer" className="disabled">
                            About GAA
                        </FooterLink>
                        <FooterLinkSeparator>|</FooterLinkSeparator>
                        <FooterLink target="_blank" rel="noreferrer" className="disabled">
                            Privacy Policy
                        </FooterLink>
                        <FooterLinkSeparator>|</FooterLinkSeparator>
                        <FooterLink target="_blank" rel="noreferrer" className="disabled">
                            Terms and Conditions
                        </FooterLink>
                        <FooterLinkSeparator>|</FooterLinkSeparator>
                        <FooterLink target="_blank" rel="noreferrer" className="disabled">
                            Located in Washington DC
                        </FooterLink>
                        <FooterLinkSeparator>|</FooterLinkSeparator>
                        <FooterLink href={LINK_M}>
                            Contact Us at info@globalactionalliance.net
                        </FooterLink>
                        <FooterLinkSeparator>|</FooterLinkSeparator>
                        <FooterLink href={LINK}>
                            Share additional data
                        </FooterLink>
                    </LinkContainer> 
                }
            </FooterContainer>
        )
}

export default Footer;