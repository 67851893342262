import { media } from "assets/static";
import styled from "styled-components";
import auth_background from "assets/image/auth_background.png";
import trademarkLogo from "assets/image/sm_trademark.svg";


export const InfoArea = styled.main`
    width: 35vw;
    background: white;
    display: flex;
    justify-content: center;
`

export const InfoAreaForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3.5vw;
`

export const InfoAreaHeadingContainer = styled.div`
    display: flex;
    align-items: center;
    color: #30466C;
    font-size: 2rem;
    font-weight: ${props => props.theme.heading1.fontWeight};
    line-height: normal;
    gap: 5px;
`

export const InfoAreaSubHeading = styled.p`
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
    color: ${props => props.theme.colors.text.generalText};
`

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.padding ? props.padding : '2vh 0'};
`

export const WiserLogo = styled.img`
    height: 6.8vh;
    cursor: pointer;


    ${media.monitor} {
        height: 5.4vh;
    }

    ${media.large_desktop} {
        height: 5vh;
    }

    ${media.laptop} {
        height: 3.8vh;
    }

`