import React, { useState } from 'react';
import { PageClick } from 'components/Pagination/style.pagination';
import { BsFillCaretLeftFill, BsFillCaretRightFill } from "react-icons/bs";

import { GridWrapper, SummaryTitle, NoData } from "pages/style";
import { TagsContainer, FocueArea, PaginationContainer } from './style.homeDetails';

export default function FocusAreas({ company, issuedNum }) {

    const primary = company["Primary_Focus_Areas"] != null ? company["Primary_Focus_Areas"][issuedNum] ?? [] : [];
    const secondary = company["Secondary_Focus_Areas"] != null ? company["Secondary_Focus_Areas"][issuedNum] ?? [] : [];

    const [ ifPrimary, setIfPrimary ] = useState(true);

    return (
        primary.length === 0 && secondary.length === 0
            ? <NoData>No Focus Areas</NoData>
            : <GridWrapper>
                <SummaryTitle>
                    { ifPrimary ? "Primary Focus Areas" : "Secondary Focus Areas" }
                </SummaryTitle>
                <PaginationContainer>
                    <PageClick disabled={ifPrimary} onClick={() => { setIfPrimary(true) }}><BsFillCaretLeftFill /></PageClick>
                    <TagsContainer>
                        { getFocusArea(ifPrimary ? primary : secondary, ifPrimary) }
                    </TagsContainer>
                    <PageClick disabled={!ifPrimary} onClick={() => { setIfPrimary(false) }}><BsFillCaretRightFill /></PageClick>
                </PaginationContainer>
            </GridWrapper>
    )
}

const getFocusArea = (data, ifPrimary) => {
    return (
        data.length === 0
            ? <NoData>{`No ${ifPrimary ? "Primary" : "Secondary"} Focus Areas associated with this organization`}</NoData>
            : data.map((item, index) => {
                return (<FocueArea key={index}>{item} </FocueArea>);
            })
    );
}