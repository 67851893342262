import { createSlice } from '@reduxjs/toolkit';

export const defaultState = {
    filters: {
        year: "",
        lab: "",
        inventor: "",
        category: ""
    }
};

export const filters = createSlice({
    name: 'filters/patent',
    initialState: defaultState,
    reducers: {

        changeFilter: (state, action) => {
            state.filters[action.payload.key] = action.payload.value;
        },

        deselectFilter: (state, action) => {
            state.filters[action.payload.key] = "";
        },

        updateFilter: (state, action) => {
            state = action.payload.filters;
        }, 

        clearFilter: (state, action) => {
            state.filters = defaultState.filters;
        }

    }
})

export const filterActions = filters.actions;
export default filters.reducer;