import styled from "styled-components";

export const FocusAreaByStateConatiner = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    overflow: hidden;
`;

export const State = styled.div`
    display: flex;
    flex-direction: column;
    width: 30%;
    justify-content: space-between;
    padding-right: 1.1vw;
    overflow: hidden;
`;

export const FocusAreas = styled.div`
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
    flex-direction: row;
    
    width: 70%;
    padding: 1.6vh 0.4vw 0.8vh 0.85vw;  
    background: #E8E0ED;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 24px;
    
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */

    &::-webkit-scrollbar{
        display: none;
    }
`;

export const FocusAreaChip = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5vh 1vw;

    width: fit-content;
    height: 2vh;
    
    color: #381650;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 30px;

    font-style: normal;
    font-size: ${props => props.theme.heading6.fontSize};
    font-weight: ${props => props.theme.heading6.fontWeight};
`;


export const StateChipWrapper = styled.div`
    height: 3.5vh;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 1.4vh 0.6vw;
    gap: 5px;
    cursor: pointer;

    background: ${props => props.isSelected ? '#E8E0ED' : "#FFFFFF"};
    color: #5C2583;
    border: 1px solid #E8E0ED;
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 24px;

    font-size: ${props => props.theme.heading6.fontSize};
    font-weight: ${props => props.theme.heading6.fontWeight};
`;