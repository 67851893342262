import React from 'react';
import { AuthLayout as Layout } from 'layout';
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { validateField } from "services/Utils/Validator";
import { useAuth } from "contexts/AuthContext";
import { PORT, PATH, MODE } from "services/api";


import { 
    WiserLogo, 
    InfoAreaForm, 
    InfoAreaHeadingContainer, 
    InfoAreaSubHeading, 
    OptionsContainer,
    MInfoAreaForm,
    MInfoAreaHeading,
    MInfoAreaSubHeading,
    MInputFieldsContainer
} from "./style.Auth";
import { Checkbox, Error, InputField, RoundedButton, RoundedInputField, ShowOrHideOption, TextButton } from "components";

import ProductLogo from "assets/image/gaa-logo.png";



export default function LoginForm() {

    const navigate = useNavigate();

    const { userSignIn } = useAuth();
    const emailInputRef = useRef(null);
    const passwordInputRef = useRef(null);
    const loginButtonRef = useRef(null);
    const didMountRef = useRef(false);

    const dispatch = useDispatch();

    const initialState = {
        email: "",
        password: "",
        rememberMe: false,
    };

    const [ data, setData ] = useState(initialState);
    const [ loading, setLoading ] = useState(false);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ focusedFields, setFocusedFields ] = useState({
        email: false,
        password: false,
    });
    const [ fieldsFocusedAtLeastOnce, setFieldsFocusedAtLeastOnce ] = useState({
        email: false,
        password: false,
    });
    const [ errorMessages, setErrorMessages ] = useState({ 
        "areValid": true,
        "errors": {
            "email": { 
                isValid: true, 
                error: null
            },
            "password": { 
                isValid: true, 
                error: null
            },
            "others": { 
                isValid: true, 
                error: null
            },
        }
    });

    const handleIconClick = () => {
        return window.open("https://www.globalactionalliance.net/", "_blank")
    }

    const handleInputChange = (label, event) => {
        setData({
            ...data,
            [label]: event.target.value
        });
    }

    const handleFocusChange = (label, value) => {
        setFocusedFields({
            ...focusedFields,
            [label]: value
        });
        
        if(value && !fieldsFocusedAtLeastOnce.label){
            setFieldsFocusedAtLeastOnce({
                ...fieldsFocusedAtLeastOnce,
                [label]: value
            })
        }
    }

    const validateInputFields = (emailRef, pwdRef) => {
        
        let output = { areValid: true, errors: ""}

        const emailValidationResult = validateField("email", [emailRef]);
        const pwdValidationResult = validateField("password", [pwdRef]);

        if (emailValidationResult.isValid && pwdValidationResult.isValid) {
            output = { 
                areValid: true, 
                errors: { 
                    "email": emailValidationResult, 
                    "password": pwdValidationResult 
                }}
        } else {
            output = { 
                areValid: false, 
                errors: { 
                    "email": emailValidationResult, 
                    "password": pwdValidationResult 
                }}
        }

        return output;
    }

    useEffect(()=>{
        if (didMountRef.current) {
            const areInputFieldsValid = validateInputFields(emailInputRef, passwordInputRef);
            if ( areInputFieldsValid.areValid == false ){
                setIsSubmitting(false);
                setErrorMessages({
                    ...errorMessages,
                    areValid: areInputFieldsValid.areValid,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: areInputFieldsValid.errors.email.isValid, 
                            error: areInputFieldsValid.errors.email.error},
                        password: { 
                            ...errorMessages.errors.password, 
                            isValid: areInputFieldsValid.errors.password.isValid, 
                            error: areInputFieldsValid.errors.password.error},
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            } else {
                setIsSubmitting(true);
                setErrorMessages({
                    ...errorMessages,
                    areValid: true,
                    errors: {
                        ...errorMessages.errors,
                        email: { 
                            ...errorMessages.errors.email, 
                            isValid: true, 
                            error: null},
                        password: { 
                            ...errorMessages.errors.password, 
                            isValid: true, 
                            error: null},
                        others: {
                            ...errorMessages.errors.others,
                            isValid: true,
                            error: null
                        }
                    }
                });
            }
        } else {
            didMountRef.current = true;
            loginButtonRef.current.disabled = true;
        }
    },[data]);

    const handleSubmit = async () => {

        if(isSubmitting == false)
            return;

        setLoading(true);
        const response = await fetch(`${MODE}://${PATH}:${PORT}/v1/user/login`, {
            method: "post",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: data.email.trim(),
                password: data.password.trim(),
                isPersistent: data.rememberMe
            })
        }).then(rawResponse => {
            if (rawResponse.ok) {
                console.log(rawResponse)
                return rawResponse.json();
            }
            throw rawResponse.statusText;
        })
        .then(resJson => {
            const token = resJson.token;
            userSignIn(token);
            setLoading(false);
            navigate("/");
        })
        .catch(error => {
            console.log(error);
            setIsSubmitting(false);
            setErrorMessages({
                ...errorMessages,
                areValid: false,
                errors: {
                    ...errorMessages.errors,
                    others: {
                        ...errorMessages.errors.others, 
                        isValid: false, 
                        error: error.statusText || error.message
                    }
                }
            });
        })
        .finally(() => setLoading(false));
    }

    const toggleRememberMe = () => {
        setData({
            ...data,
            rememberMe: !data.rememberMe,
        });
    }

    return (
        <Layout
            title={"Clinical Trials"}
            isLoaderDisplay={false}
            footerRelative={true}
            isDeviceMobile={false}
        >
            <InfoAreaForm>
                <InfoAreaHeadingContainer>
                    <WiserLogo id="org-logo" src={ProductLogo} alt="GAA" onClick={handleIconClick} />
                    <div>Log in</div>
                </InfoAreaHeadingContainer>
                <InfoAreaSubHeading>
                    This is a secure system. Please enter company credentials in order to access your dashboard.
                </InfoAreaSubHeading>

                <InputField 
                    name={"Company email ID"}
                    type={"email"}
                    value={data.email}
                    innerRef={emailInputRef}
                    required={true}
                    className={ errorMessages.errors.email.isValid ? "" : "invalid" }
                    onChangeCallback={(e) => {handleInputChange("email", e)}}
                    onFocusCallback={() => {handleFocusChange("email", true)}}
                    onBlurCallback={() => {handleFocusChange("email", false)}}
                    keyDownCallback={handleSubmit}
                />
                <Error 
                    isVisible={ !(errorMessages.errors.email.isValid || focusedFields.email) && fieldsFocusedAtLeastOnce.email }
                    error={ errorMessages.errors.email.error }
                />

                <InputField 
                    name={"Password"}
                    type={"password"}
                    value={data.password}
                    innerRef={ passwordInputRef }
                    required={true}
                    className={ errorMessages.errors.password.isValid ? "" : "invalid" }
                    onChangeCallback={(e) => {handleInputChange("password", e)}}
                    onFocusCallback={() => {handleFocusChange("password", true)}}
                    onBlurCallback={() => {handleFocusChange("password", false)}}
                    keyDownCallback={handleSubmit}
                    inputOption={ <div style={{ margin: '0 0 0 1vw' }}><ShowOrHideOption fieldRef={passwordInputRef} /></div> }
                    // options={<TextButton text='Forgot password?' handleClick={() => navigate('/forgot-password')}/>}
                />
                <Error 
                    isVisible={ !(errorMessages.errors.password.isValid || focusedFields.password) && fieldsFocusedAtLeastOnce.password }
                    error={ errorMessages.errors.password.error }
                />
                <Error 
                    isVisible={ !errorMessages.errors.others.isValid }
                    error={ errorMessages.errors.others.error }
                />

                <OptionsContainer>
                    <Checkbox 
                        label={'Remember me'}
                        value={data.rememberMe}
                        handleChange={toggleRememberMe} 
                    />
                    <RoundedButton 
                        width={'7.8vw'}
                        height={'4.5vh'}
                        textColor={"white"}
                        backgroundColor={'#23647C'}
                        borderRadius={'14px'}
                        innerRef={loginButtonRef}
                        disabled={ isSubmitting ? false : true }
                        handleClick={handleSubmit}
                    >
                        {'Log In'}
                    </RoundedButton>
                </OptionsContainer>
            </InfoAreaForm>
        </Layout>
    );
}
