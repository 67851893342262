import styled from "styled-components";
import { media } from 'assets/static/index';
import backGroundImage from 'assets/image/header_background.png';

//---------------Layout------------------
export const LayoutProvider = styled.div`
    width: 100%;
    min-height: 100vh;

    display: flex;
    flex-direction: column;
    gap: 0.8vh;

    font-size: ${props => props.theme.bodyText.fontSize};
    font-weight: ${props => props.theme.bodyText.fontWeight};
`

export const BodyContainer = styled.div`
    /* width: 100%; */
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.8vh;
    padding: 0.4vh 1.55vw;
    margin-top: 7vh;
`


//------------Loader--------------------
export const LoaderContainer = styled.div`
    display: ${(props) => !props.isLoaderDisplay ? 'none' : 'flex'};
    
    position:fixed;
    top: 0;
    left: 0;

    width: 100%;
    height: 100vh;

    align-items: center;
    justify-content: center;

    z-index: 100;

`


export const LoaderModal = styled.div`
    position: fixed;
    left: 50%;
    top: 25%;

    width: 22%;
    height: 24%;

    z-index: 110;

    background-color: ${props=> props.theme.colors.generalWhite};
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    border-radius: 24px;

    color: ${props=> props.theme.colors.text.clickableText_2};

    padding: 2vh;

    transform: translateX(-50%);

    display: ${props => props.isDisplay ? 'flex' : 'none'};

    /* display: flex; */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2.6vh;
`

export const LoadingImage = styled.img`
    width: 120px;
    height: 120px;

    animation: rotation 2s infinite linear;

    @keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }
}
`

export const PopupBackdropContainer = styled.div`
    position: fixed;
    z-index: ${props => props.zIndex ? props.zIndex : `100`};
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${props=> props.theme.colors.darkGrey};
    opacity: 0.7;

    backdrop-filter: blur(10px);

    display: ${props=> props.isDisplay ? 'block' : 'none'};
`


//------------Header--------------------
export const HeaderContainer = styled.header`
    box-sizing: border-box;
    position: ${props => props.isHeaderFixed ? 'fixed' : ''};
    width: 100%;
    min-height: 5.5vh;
    height: auto;
    padding: 0 1vw;
    z-index: 50;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1vw;

    background-size: cover;
    background-position: center center;
    background-image: url(${backGroundImage});
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
`

export const HeaderTitle = styled.div`
    display: flex;
    color: #F7F7F6;
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: 200;
    line-height: 29px;
`

export const LogoComponent = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;


    #org-logo {
        height: 65px;
    }

    ${media.monitor} {
        #org-logo {
            height: 45px;
        }
    }

    ${media.large_desktop} {
        #org-logo {
            height: 34px;
        }
    }

    ${media.laptop} {
        #org-logo {
            height: 26.7px;
        }
    }
`;

export const TitleImage = styled.img`
    height: 30px;
    color: #fff;
    font-size: ${props => props.theme.heading3.fontSize};
    font-weight: ${props => props.theme.heading3.fontWeight};

    /* transition: all 2s; */

    ${media.monitor} {
        height: 28px;
    }

    ${media.large_desktop} {
        height: 15px;
    }

    ${media.laptop} {
        height: 15px;
    }
`;


//------------Breadcrumb----------------
export const BreadcrumbWrapper = styled.div`  
    display: flex;
    align-items:center;
    justify-content: space-between;
`

export const Breadcrumb = styled.div`
    display: flex;
    justify-content: flex-start;
    margin: 0.57vh 0 0vh 0;
    
    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontSize};
    font-style: normal;
    color: #000;

    span:first-child {
        display: flex;
        margin-left: 0;
    }
`
export const Link = styled.span`
    margin-left: 5px;
    color: #437E8D;
    cursor: pointer;

    &::after {
        content : '>';
        margin-left: 5px; 
    }
`

export const Typography = styled.span`
    margin-left: 5px;
    cursor: pointer;
`

export const TextButton = styled.a`
    color: #584A9F;
    text-decoration: none;
    cursor: pointer;

    font-size: ${props=> props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontSize};
    font-style: normal;

    color: #549EB0;

    &:hover {
        color: #549EB0;
    }
`

//------------------Title Container----------------

export const TitleContainer = styled.div`
    font-size: ${props=> props.theme.heading2.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
    color: #000000;
`

//------------Footer--------------------

export const FooterContainer = styled.footer`
    box-sizing: border-box;
    position:  ${(props) => !props.relative && "fixed"};
    bottom: 0px;
    padding: 1.1vh 0;
    margin-top: auto;
    background-color: ${props => props.theme.colors.text.generalText};
    color : #FFFFFF;
    width: 100%;
    height: ${props => props.showSingleLine ? '3vh' : '5.5vh' };
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: ${props => props.showSingleLine ? props.theme.heading3.fontSize : props.theme.bodyText.fontSize};
    font-weight: 200;
    z-index: 50;

    ${media.tablet} {
        font-size: ${props => props.theme.heading3.fontSize};
    }

    ${media.mobile} {
        font-size: ${props => props.theme.heading4.fontSize};
    }
`
export const LinkContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: baseline;
`
export const FooterHeading = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin-bottom: .1vh;

    #org-logo {
        height: 20px;
        margin-right: 3px;
    }

    ${media.monitor} {
        #org-logo {
            height: 15px;
        }
    }

    ${media.large_desktop} {
        #org-logo {
            height: 10px;
        }
    }

    ${media.laptop} {
        #org-logo {
            height: 7px;
        }
    }
`
export const FooterLink = styled.a`
    text-decoration: none;
    line-height: 14px;
    color : #FFF;

    &:hover {
        text-decoration: underline;
        color : #CFDBE3;
    }

    &.disabled {
        text-decoration: none;
        color : #FFF;
    }
`
export const FooterLinkSeparator = styled.div`
    font-weight: ${props=> props.theme.heading6.fontWeight};
    font-size: ${props=> props.theme.heading6.fontSize};
    margin : 0px 8px;
`