import styled from "styled-components";

export const FieldContainer = styled.div`
    width: ${props => props.width ? props.width : '100%'};
    height: ${props => props.height ? props.height : '5.1vh'};

    display: flex;
    flex-direction: column;
    margin: ${props => props.margin ? props.margin : '2vh 0'};

    
    font-size: ${props => props.theme.heading4.fontSize};
    line-height: normal;
    color: ${props => props.theme.colors.text.secondaryText};
`

export const FieldHeader = styled.div`
    display: flex;
    justify-content: space-between;
    font-weight: ${props => props.theme.heading4.fontWeight};
`

export const FieldName = styled.div`
    max-width: 66.5%;
`

export const FieldOptions = styled.div`
    max-width: 50%;
    color: ${props => props.theme.colors.text.clickableText};
`

export const FieldInputContainer = styled.div`
    display: flex;
    border-bottom: 1px solid black;
    padding: 0.9vh 0 0.5vh 0;
`

export const FieldInput = styled.input`
    width: ${props => props.inputOption ? '-webkit-fill-available' : '100%'};
    color: ${props => props.theme.colors.text.secondaryText};
    box-sizing: border-box;
    border: none;

    &:focus {
        outline: none;
    }
    &:invalid {
        color: #AB4646;
    }
    &:autofill {
        background: white;
        color: ${props=> props.theme.colors.text.secondaryText};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        caret-color: ${props=> props.theme.colors.black};
        -webkit-text-fill-color: ${props=> props.theme.colors.text.secondaryText};
        -webkit-box-shadow: 0 0 0px 30px white inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    /* Disable default show-hide icon of password input in MS-Edge  */
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }
`

export const FieldInputOption = styled.div`
    max-width: fit-content;
    color: ${props => props.theme.colors.text.generalText};
`

export const RoundedInputFieldContainer = styled.div`
    display: flex;
    align-items: center;
    margin: ${props => props.margin ? props.margin : ''};
    width: 100%;
`;

export const RoundedInputField = styled.input`
    width: 100%;
    height: 6.8vh;
    margin: 1% 0;
    padding: 0 12% 0 6.3%;

    box-sizing: border-box;
    background: white;
    border-radius: 76.52px;
    border: none;
    
    font-size: ${props=> props.fontSize ? props.fontSize : props.theme.heading2.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};
    
    color: ${props => props.theme.colors.text.generalText};

    &:focus {
        outline: none;
    }
    &:invalid {
        border: 1px solid #AB4646;
    }
    &:autofill {
        background: white;
        color: ${props=> props.theme.colors.text.generalText};
    }
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        caret-color: ${props=> props.theme.colors.black};
        -webkit-text-fill-color: ${props=> props.theme.colors.text.generalText};
        -webkit-box-shadow: 0 0 0px 30px white inset;
        transition: background-color 5000s ease-in-out 0s;
    }
    &::placeholder {
        color: ${props=> props.theme.colors.text.secondaryText};
    }
    /* Disable default show-hide icon of password input in MS-Edge  */
    &::-ms-reveal,
    &::-ms-clear {
        display: none;
    }
`;

export const CheckboxWrapper = styled.label`
    display: flex;
    align-items: center;
    color: ${props => props.theme.colors.text.clickableText};
    font-size: ${props=> props.fontSize ? props.fontSize : props.theme.heading4.fontSize};
    font-weight: ${props=> props.theme.heading4.fontWeight};
`;

export const ErrorContainer = styled.div`
    color: #AB4646;
    display: flex;
    margin: ${props => props.margin ? props.margin : '0.5vh 0 0 0'};
    gap: 10px;

    font-size: ${props=> props.theme.heading5.fontSize};
    font-weight: ${props=> props.theme.heading5.fontWeight};
    line-height: normal;
`;