import { SearchBar } from "./style.searchBar";
import { SearchBtn } from "../index";
import { useRef } from "react";


const SearchBarCont = (props) => {

    const inputRef = useRef();

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSearchSubmit();
        }
    };

    const handleSearchSubmit = () => {
        props.submitCallback(inputRef.current.value);
    }

    return (
        <SearchBar
            width={props.width}
            height={props.height}
        >
            <input ref={inputRef} onKeyDown={handleKeyDown} placeholder={props.placeHolder}/>
            <SearchBtn
                handleSearchSubmit={handleSearchSubmit}
                margin={'0rem 0.2rem 0rem 0rem'}
            />
        </SearchBar>
    );
}

export default SearchBarCont;