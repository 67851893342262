import styled from "styled-components";
import { media } from "assets/static";
import auth_background from "assets/image/auth_background.png";
import trademarkLogo from "assets/image/sm_trademark.svg";

export const LogInContainer = styled.div`
    display: flex;
    width: 100%;
    height: 94.5vh; /* To exclude footer */
    background: #F7F7F7;
`

export const GreetingsArea = styled.div`
    width: 85vw;
    ${'' /* height: 100vh; */}
    background-image: url(${auth_background});
    ${'' /* background-size: 110%; */}
    ${'' /* background-position: 10% 35%; */}
    ${'' /* background-size: 120%; */}
    ${'' /* background-position: 25% 34%; */}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 25% 26%;
`

export const GreetingsContainer = styled.div`
    width: 45.11vw;
    ${'' /* height: 14.4vh; */}
    border-radius: 12px;
    color: #F7F9FF;
    font-weight: 200;

    display: flex;
    flex-direction: column;
    position: absolute;
    top: 8.5vh;
    left: 6vw;
`

export const GreetingsHeading = styled.span`
    width: fit-content;
    font-size: ${props => props.theme.heading0.fontSize};
    line-height: normal;
`

export const GreetingsSubHeading = styled.div`
    margin-top: 1.5vh;
    font-size: ${props => props.theme.heading3.fontSize};
    line-height: normal;
`

export const InfoArea = styled.main`
    width: 35vw;
    background: white;
    display: flex;
    justify-content: center;
`

export const InfoAreaForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 3.5vw;
`

export const InfoAreaHeadingContainer = styled.div`
    display: flex;
    align-items: center;
    color: #30466C;
    font-size: 2rem;
    font-weight: ${props => props.theme.heading1.fontWeight};
    line-height: normal;
    gap: 5px;
`

export const InfoAreaSubHeading = styled.p`
    font-size: ${props => props.theme.heading4.fontSize};
    font-weight: ${props => props.theme.heading4.fontWeight};
    color: ${props => props.theme.colors.text.generalText};
`

export const OptionsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${props => props.padding ? props.padding : '2vh 0'};
`

export const WiserLogo = styled.img`
    height: 6.8vh;
    cursor: pointer;


    ${media.monitor} {
        height: 5.4vh;
    }

    ${media.large_desktop} {
        height: 5vh;
    }

    ${media.laptop} {
        height: 3.8vh;
    }

`;

export const TrademarkLogo = styled.div`
    background-image: url(${trademarkLogo});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 32px;
    height: 32px;

    ${media.large_desktop} {
        width: 16px;
        height: 16px;
    }
`