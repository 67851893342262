import { useEffect, useState } from "react";
import ReactDom from 'react-dom';
import { BsTriangleFill } from "react-icons/bs";
import { TopScrollBtnContainer } from "./style.button";

const TopScrollButton = (props) => {

    const [ atTop, setAtTop ] = useState(true);

    useEffect(() => {
        window.onscroll = () =>
            window.scrollY === 0 ? setAtTop(true) : setAtTop(false);
    
        return () => (window.onscroll = null);
    });

    return ReactDom.createPortal(
        <TopScrollBtnContainer 
            width={props.width}
            margin={props.margin}
            fontSize={props.fontSize}
            gap={props.gap}
            color={props.color}
            onClick={props.handleOnClick}
            ifDisplay={!atTop}
        >
                <BsTriangleFill/>
                <div>{props.label}</div>
        </TopScrollBtnContainer>,
        document.getElementById("to-top-btn")
    );
}

export default TopScrollButton;