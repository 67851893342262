import React, { useContext, useEffect, useState } from "react";
import { PORT, PATH, MODE } from "services/api";

export const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {

    const [ currentUser, setCurrentUser ] = useState();
    const [loading, setLoading] = useState(true);


    const userSignIn = (userInfo, rememberToken) => {
        localStorage.setItem("token", JSON.stringify(userInfo));
        setCurrentUser(userInfo);
    }

    const userLogOut = () => {
        if( localStorage.getItem("token") )
            localStorage.removeItem("token");
        setCurrentUser();
    }

    useEffect(() => {
        const token = JSON.parse(localStorage.getItem('token') || null);
        setCurrentUser(token);
        setLoading(false);

        const dummyLogIn = async () => {
            const response = await fetch(`${MODE}://${PATH}:${PORT}/v1/user/login`, {
                method: "post",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    "email": "test@test.com",
                    "password":"test12345",
                    "isPersistent": true
                })
            }).then(rawResponse => {
                if (rawResponse.ok) {
                    return rawResponse.json();
                }
                throw rawResponse.statusText;
            })
    
            return response;
        }
    }, [])

    return (
        <AuthContext.Provider value={{currentUser, userSignIn, userLogOut}}>
            {/* Don't render children until loading is done */}
            {!loading && children}
        </AuthContext.Provider>
    )
}