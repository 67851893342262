import styled from "styled-components";
import { homeInputStyle } from 'assets/static/static.css';
import { TextButton } from '../Default/style';
import { media } from "assets/static";


export const FiltersContainer = styled.div`
    display: flex;
    align-items: flex-start;
    left: 4.26vw;
    font-size: ${props=> props.theme.bodyText.fontSize};
    font-weight: ${props=> props.theme.bodyText.fontWeight};
    width: fit-content;
    margin: 0 auto;
`

export const FilterWrapper = styled.div`
    box-shadow: 3px 12px 20px rgba(88, 158, 195, 0.15);
    background: transparent;
    border-radius: 44px;

    max-height: ${props => props.ifOpen ? '34vh' : '3.5vh'};
    margin-right: 1vw;
    /* min-width: 4vw; */
    padding: 0 30px;
    padding-bottom: ${props => props.ifOpen ? '5px' : '0px'};
    background-color: ${props => props.ifApplied ? props.theme.colors.text.clickableText : '#FAFAF9'};
    overflow: hidden;
    color: ${(props) => (props.ifApplied ? "#FAFAF9" : props.theme.colors.text.clickableText)};

    transition: max-height 0.2s;

    & input {
        color: ${props => props.ifApplied ? '#FAFAF9' : props.theme.colors.text.clickableText};
        font-size: ${props => props.theme.bodyText.fontSize};
    }

    &:hover {
        background-color: ${props => 
            (!props.ifApplied && !props.ifOpen) && props.theme.colors.lightestBlue ||
            (props.ifApplied && !props.ifOpen) && props.theme.colors.commonBlue
        };
        cursor: pointer;
    }

    ${media.monitor} {
        border-radius: 24px;
    }
`

export const FilterLabel = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
`

export const FilterIcon = styled.span`
    font-size: ${(props) => props.theme.bodyText.fontSize};
    transform: ${(props) => (props.ifOpen ? "rotate(0deg)" : "rotate(180deg)")};
    transition: transform 0.4s;
`;


export const Input = styled.input`
    ${homeInputStyle}
    padding: 0;
    text-align: left;
    height: 3.5vh;

    &::placeholder {
        color: inherit
    }
`

export const OptionsMenu = styled.ul`
    list-style: none;
    padding: 10px 20px 10px 10px;
    margin: 0;
    width: 100%;
    max-width: 15vw;
    max-height: 25vh;

    border-top: ${props => '1px solid' + props.theme.colors.text.generalText};

    overflow-y: auto;

    &::-webkit-scrollbar {
        width: 0.4rem;
    }

    &::-webkit-scrollbar-track {
        /* background-color: rgb(221, 221, 221);
        border-radius: 100vw; */
        margin-top: 0.4em;
    }
`

export const OptionItem = styled.li`
    width: 85%;
    padding: 2px 1px 2px 5px;
    cursor: ${props => props.ifSelectable ? "pointer" : "default"};
    color: ${
        props => props.ifApplied ? 
            (props => props.ifSelectable ? "#FAFAF9" : "#B7B0D3")
            : (props => props.ifSelectable ? props.theme.colors.text.generalText : "#C4C4C4")
    };
    overflow-x: hidden;

    &:hover {
        background-color: ${
            props => props.ifApplied ? 
                (props => props.ifSelectable ? props.theme.colors.commonBlue : "inherit")
                : (props => props.ifSelectable ? props.theme.colors.lightestBlue : "inherit")
        };
        border-radius: 6px;
    }
`

export const ResetFilter = styled(TextButton)`
    line-height: 3.5vh;
    color: ${props => props.theme.colors.text.generalText};
    font-size: ${props=> props.theme.heading4.fontSize};
    white-space: nowrap;

    &:hover {
        cursor: pointer;
    }
`
