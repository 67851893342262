import React, { Component } from "react";
import { AuthContext } from 'contexts/AuthContext';
import withRouter from "utils/ComponentWrapper";

import { Tabs, Loader, US_Map, InfoBubble } from "components";
import { GridWrapper, SummaryTitle, NoData, SummaryTitleWrapper, CardOptionsWrapper } from "pages/style";
import { AboutContentBottomBlur, AboutContent, About, Contacts, ManufactureFunding, HomeGrid, ManufactureLicensed, ManufactureMaps, HomeDetailContainer, ManufactureTrials, TableBody, TableHead, TableDataDiv, TableBodyByLicensed, TableHeadLicensed, TableContainerLicensed, TotalGovernmentFunding, TotalPrivateFunding, TableContainerCompanyInfo, TotalFundingAmountHomeD, TableContainer2 } from './style.homeDetails';
import { Layout } from "layout";
import FocusAreas from "./FocusAreas";

const colorLabel = [
    "#5C2583",
    "#5C2583",
    "#584A9F",
    "#584A9F",
    "#796CB1",
    "#796CB1",
    "#DED3E6",
    "#DED3E6",
    "#F0F0F0",
];

class HomeDetails extends Component {

    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            manufacturer: "",
            loading: false,

            AboutContent: "",
            mapData: "",
            clinicalTrialsCount: 0,
            clinicalTrials: [],
            vaccineAvailabilityCount: 0,
            fundingCount: 0,
            fundingAmount: 0,
            vaccineAvailability: [],
            contacts: [],
            annualReport: "",
            linkedInLink: "",
            pressRelease: "",
            websiteLink: "",

            info: {
                "founder": "-",
                "year": "-",
                "status": "-"
            },
            funding: {
                "government": 0,
                "private": 0
            },
            focusAreas: [],

            currentPage: 0,
            tableContent: 2,
            governmentFunding: 1,
            currentPageContacts: 0,
            tableContentContacts: 3,

            focusAreasPage: 1,
            totalFocusAreaToDisp: 8
        };
    }

    render = () => {
        const { location, params } = this.props.router;
        const company = location.state;
        const issuedNum = params.issuedNumber;

        return (
            <HomeDetailContainer>
                <Loader isVisible={ this.state.loading } />
                <Layout
                    title={company["Organization Name"]}
                    searchSubmitCallback={this.handleSearchSubmitCallback}
                >
                    <Tabs
                        stats={{}}
                        linkStats={[
                            { "View Annual Report": this.state.annualReport },
                            { [`Take me to ${company["Company_Name"]}`]: company["Website"]},
                            { "Take me to LinkedIn": company["LinkedIn_URL"] },
                            { [`Take me to Press Release`]: this.state.pressRelease },
                        ]}
                    />
                    <HomeGrid>
                        <About style={{ position: 'relative', overflow: 'hidden' }}>
                            <GridWrapper>
                                <SummaryTitleWrapper>
                                    <SummaryTitle>About</SummaryTitle>
                                    <CardOptionsWrapper>
                                        <InfoBubble
                                            disclaimer={"NA"}
                                            disabled={true}
                                        />
                                    </CardOptionsWrapper>
                                </SummaryTitleWrapper>
                                {
                                    company["Description"] && company["Description"].length > 0 
                                        ? <AboutContent>{company["Description"]}</AboutContent>
                                        : <NoData>No Description Associated with Organization</NoData>
                                }
                                <AboutContentBottomBlur />
                            </GridWrapper>
                        </About>

                        <TotalGovernmentFunding>
                            <GridWrapper>
                                <SummaryTitle> { `Total Government Funding (contracts & grants)` }</SummaryTitle>
                                <TotalFundingAmountHomeD>
                                { company["Government_Funding"] ? `\$ ${company["Government_Funding"]}` : `-` }
                                </TotalFundingAmountHomeD>
                            </GridWrapper>
                        </TotalGovernmentFunding>

                        <TotalPrivateFunding>
                            <GridWrapper>
                                <SummaryTitle>
                                    { `Total Private Funding` }
                                </SummaryTitle>
                                    <TotalFundingAmountHomeD>
                                        { company["Total_Funding"] ? `\$ ${company["Total_Funding"]}` : `-` }
                                    </TotalFundingAmountHomeD>
                            </GridWrapper>
                        </TotalPrivateFunding>

                        <ManufactureLicensed>
                            <GridWrapper>
                                <SummaryTitle>Company Information</SummaryTitle>
                                {
                                    (company["Founders"] && company["Founding_Year"]) 
                                        ? <TableContainerLicensed calcHeight={ false }>
                                            <TableHeadLicensed>
                                                <tr>
                                                    <th style={{width: "55%"}}>Founder(s)</th>
                                                    <th>Founding Year </th>
                                                </tr>
                                            </TableHeadLicensed>
                                            <TableBodyByLicensed>
                                            <tr>
                                                <td ><TableDataDiv>{company["Founders"] ?? "-"}</TableDataDiv></td>
                                                <td ><TableDataDiv>{company["Founded_Year"] ?? "-"}</TableDataDiv></td>
                                            </tr>
                                            </TableBodyByLicensed>
                                        </TableContainerLicensed>
                                        : <NoData>No Information Associated with Organization</NoData>
                                }
                            </GridWrapper>
                        </ManufactureLicensed>

                        <Contacts>
                            { (company["Email"] && company["Phone"]) ?
                                <GridWrapper>
                                    <SummaryTitle>
                                        Contacts
                                    </SummaryTitle>
                                        <TableContainerCompanyInfo
                                            calcHeight={false}
                                        >
                                            <TableHead>
                                                <tr>
                                                    <th style={{ width: "35%"}}>Email</th>
                                                    <th>Phone Number</th>
                                                </tr>
                                            </TableHead>
                                            <TableBody>
                                                <tr>
                                                    <td><TableDataDiv>{company["Email"] ?? "-"}</TableDataDiv></td>
                                                    <td><TableDataDiv>{company["Phone"] ?? "-"}</TableDataDiv></td>
                                                </tr>
                                            </TableBody>
                                        </TableContainerCompanyInfo>
                                </GridWrapper>
                                :
                                <NoData>
                                    <span style={{ inlineSize: '77%' }}>
                                        No Contact Associated with Organization
                                    </span>
                                </NoData>
                            }
                        </Contacts>

                        <ManufactureMaps>
                            <FocusAreas 
                                company={company}
                                issuedNum={issuedNum}
                            />
                        </ManufactureMaps>

                        <ManufactureFunding>
                            { 
                                company["Top_5_Investors"] && company["Top_5_Investors"].length > 0
                                    ? <GridWrapper>
                                        <SummaryTitle>
                                            {`Top 5 Investors`}
                                        </SummaryTitle>
                                            <TableContainer2 calcHeight={false}>
                                                <TableHeadLicensed>
                                                    <tr>
                                                        <th width={"10%"}>No.</th>
                                                        <th>Investors</th>
                                                    </tr>
                                                </TableHeadLicensed>
                                                <TableBodyByLicensed>
                                                    { this.getInvestors(company) }
                                                </TableBodyByLicensed>
                                            </TableContainer2>
                                    </GridWrapper>    
                                    : <NoData>
                                        <div style={{ inlineSize: '57.4%' }}>
                                            No Investors Associated with Organization
                                        </div>
                                    </NoData> 
                            }
                        </ManufactureFunding>

                        <ManufactureTrials>
                            <GridWrapper>
                                <SummaryTitle style={{ paddingBottom: "5px"}}>
                                    Headquarter Location 
                                    <br /> 
                                    <span style={{fontWeight: "500"}}>
                                        { company?.Location ? `${company?.Location?.state}, ${company.Location.city ?? null}` : null }
                                    </span>
                                </SummaryTitle>
                                <US_Map state={company?.Location?.state} city={company?.Location?.city} />
                            </GridWrapper>
                        </ManufactureTrials>
                    </HomeGrid>
                </Layout>
            </HomeDetailContainer>
        );
    }

    getShadePlate = () => {
        return colorLabel.map((value, index) => {
            return (
                <span
                    key={index}
                    style={{
                        backgroundColor: `${value}`,
                        width: "1.3vw",
                        height: "1.5vh",
                    }}
                />
            );
        });
    }

    getInvestors = (company) => {
        return company["Top_5_Investors"].map((item, index) => {
            return (
                <tr key={index} style={{ fontSize: '10px' }}>
                    <td><TableDataDiv>{index+1}</TableDataDiv></td>
                    <td><TableDataDiv>{item}</TableDataDiv></td>
                </tr>
            );
        });
    }
}

export default withRouter(HomeDetails);