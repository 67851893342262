export const USA_COORDINATES = {
    "Alabama": [-86.8250, 32.8067],
    "Alaska": [-148.0000, 64.0000],
    "Arizona": [-111.4312, 34.1682],
    "Arkansas": [-92.3731, 34.7465],
    "California": [-119.4179, 36.7783],
    "Colorado": [-105.5478, 39.5501],
    "Connecticut": [-72.6658, 41.5834],
    "Delaware": [-75.5050, 39.1450],
    "Florida": [-82.3617, 27.7663],
    "Georgia": [-83.1669, 32.6781],
    "Hawaii": [-157.4983, 21.3049],
    "Idaho": [-114.4788, 44.0682],
    "Illinois": [-89.1965, 40.6331],
    "Indiana": [-86.2542, 39.8647],
    "Iowa": [-93.2105, 41.9383],
    "Kansas": [-96.7265, 38.5111],
    "Kentucky": [-84.6701, 37.6690],
    "Louisiana": [-91.8749, 31.1801],
    "Maine": [-69.2428, 45.3695],
    "Maryland": [-76.7970, 39.0550],
    "Massachusetts": [-71.2092, 42.2596],
    "Michigan": [-84.5467, 44.3467],
    "Minnesota": [-94.3053, 46.2807],
    "Mississippi": [-89.6014, 32.7364],
    "Missouri": [-92.4577, 38.3566],
    "Montana": [-110.3626, 46.8797],
    "Nebraska": [-99.7978, 41.5378],
    "Nevada": [-117.0320, 39.3289],
    "New Hampshire": [-71.5771, 43.6805],
    "New Jersey": [-74.4057, 40.1907],
    "New Mexico": [-106.2371, 34.4071],
    "New York": [-74.0060, 40.7128],
    "North Carolina": [-79.0193, 35.7822],
    "North Dakota": [-100.3020, 47.5515],
    "Ohio": [-82.9071, 40.1904],
    "Oklahoma": [-97.0929, 35.5653],
    "Oregon": [-122.6750, 44.5720],
    "Pennsylvania": [-77.1945, 40.5908],
    "Rhode Island": [-71.4113, 41.6809],
    "South Carolina": [-81.0348, 33.8569],
    "South Dakota": [-99.9018, 44.2998],
    "Tennessee": [-86.3505, 36.1658],
    "Texas": [-99.9018, 31.9686],
    "Utah": [-111.5472, 40.1135],
    "Vermont": [-72.7317, 44.0592],
    "Virginia": [-78.6382, 37.7693],
    "Washington": [-120.7401, 47.6062],
    "West Virginia": [-80.4549, 38.5976],
    "Wisconsin": [-89.9941, 43.7844],
    "Wyoming": [-107.2903, 42.9957],
    "Puerto Rico": [-66.59, 18.22],
}