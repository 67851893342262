import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import { FaEye, FaRegEyeSlash } from "react-icons/fa";

const ShowOrHideOption = (props) => {
    
    const [ passwordVisibility, setPasswordVisibility ] = useState(false);

    useEffect( () => {
        if( passwordVisibility )
            props.fieldRef.current.type = "text";
        else
            props.fieldRef.current.type = "password";
    }, [passwordVisibility] )

    const togglePassword = () => {
        setPasswordVisibility(!passwordVisibility);
    };

    return (
        <span style={{ margin: "0 0.5vw", cursor:'pointer' }} onClick={togglePassword}>
            {
                passwordVisibility 
                ? <FaEye style={{ width: "1.3vw", height: "2vh"}} /> 
                : <FaRegEyeSlash style={{ width: "1.3vw", height: "2vh"}} />
            }
        </span>
    )
}

export default ShowOrHideOption