import { configureStore } from '@reduxjs/toolkit';

import homeFilterReducer from 'store/Match/filterReducer';
import homeOptionReducer from 'store/Match/optionReducer';

import patentOptionsReducer from 'store/Patent/optionReducer';
import patentFilterReducer from 'store/Patent/filterReducer'

const store = configureStore ({
    reducer: {
        matchFilter: homeFilterReducer,
        matchOptions: homeOptionReducer,

        patentOptions: patentOptionsReducer,
        patentFilter: patentFilterReducer,
    },
});

export default store;