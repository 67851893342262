import styled from "styled-components";
import { media, theme } from "assets/static";
import { BoxedBtn } from "components";


export const HomePage = styled.div`
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  color: #164050;
`;

export const HomeHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1vw;

  & > div {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

export const OrgLogo = styled.img`
  height: 55px;
  ${media.large_desktop} {
    height: 35px;
  }
`;

export const OrgTitle = styled.h1`
  margin: 2vh 0 1.5vh 0;
  font-size: ${(props) => props.theme.heading1.fontSize};
  font-weight: ${(props) => props.theme.heading1.fontWeight};
`;

export const HeaderContainer = styled.div`
  position: ${props => props.isHeaderCollapsed ? 'fixed' : 'sticky'};
  width: 100%;
  z-index: 19;
  background-color: #F0F0F0;
  padding-bottom: ${props => props.isHeaderCollapsed ? "1.5vh" : "0" };
`

export const SearchFilterContainer= styled.div`
  width: 100%;
  position: ${props => props.isHeaderCollapsed ? 'sticky' : 'absolute'};
  top: ${props => props.isHeaderCollapsed ? '0' : '78.3vh' };
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const CardContainer = styled.main`
  margin: 1vh 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2vh;
  flex-wrap: wrap;
`;

export const OrganisationCard = styled.div`
  flex: 0 0 15vw;
  max-width: 15vw;
  
  height: max-content;
  padding: 1.5%;
  box-sizing: border-box;

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;

  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(196, 196, 196, 0.2);
  border-radius: 44px;

  ${media.monitor} {
    border-radius: 24px;
  }

  ${media.desktop} {
    flex: 0 0 18vw;
    max-width: 18vw;
  }

  ${media.laptop} {
    flex: 0 0 27vw;
    max-width: 27vw;
  }
`;

export const CardTitle = styled.p`
  font-size: ${props => props.theme.heading4.fontSize};
  font-weight: 500;
  line-height: 1.2em;
  color: #233A60;
`;

export const Type = styled.p`
  margin: 5px 0;
  font-size: ${props => props.theme.heading5.fontSize};
  font-weight: 300;
  color: ${props => props.theme.colors.text.secondaryText};
`

export const Description = styled.p`
  height: 100px;
  margin: 0 0 5% 0;
`

export const CardContentWrapper = styled.div`
  display: block; 
  width: 100%;
  height: 9rem;

  & > div:first-of-type {
    margin: 0;
    font-family: "Libre Franklin";
    font-style: normal;
    color: ${props => props.theme.colors.text.generalText};
  }

  & > p:first-of-type {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
    max-height: 10vh;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 2.5vh;
  }
`

export const CardContent = styled.p`
  margin: 0 0 6% 0;
  line-height: 1.2;
  font-size: ${(props) => props.theme.bodyText.fontSize};
  font-weight: ${(props) => props.theme.bodyText.fontWeight};
  overflow: hidden;
  display: -webkit-box !important;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  
  color: #6C7073;

  width: 100%;
`;

export const OtherTagsWrapper = styled.div`
  margin: 1.4vh 0 1vh;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;

  height: 5.5vh;
  overflow: auto;
`;

export const LocationWrapper = styled.div`
  width: fit-content;
  min-height: 5.5vh;
  display: inline-flex;
  flex-direction: row;
  gap: 0.8rem;
  align-items: flex-end;
`;

export const LearnMoreButton = styled(BoxedBtn)`
  width: 70%;
  border-radius: 24px;
  margin: 0 auto;
  font-size: ${props => props.theme.heading4.fontSize};
  font-weight: 300;

  &:hover {
    cursor: pointer;
    color: #fafaf9;
    background-color: #5095d5;
  }
`

export const SavedButton = styled.button`
  margin: "5px 0";
  width: 25%;

  background: none;
  padding: 8px;

  border: 1px solid #5095d5;
  border-radius: 14px;
  color: #5c2583;

  &:hover {
    cursor: pointer;
    color: #fafaf9;
    background-color: #5095d5;
  }
`;

export const TopScroll = styled.div`
  width: 90px;
  height: 90px;

  position: -webkit-sticky;
  position: sticky;
  
  top: 83vh;
  left: 93.5vw;

  background-color: #5C2583;
  border-radius: 10px;

  color: white;
  font-size: 1.8rem;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  z-index: 30;

  ${media.monitor} {
    width: 85px;
    height: 85px;
  }

  ${media.large_desktop} {
    width: 77px;
    height: 77px;
  }

  ${media.desktop} {
    width: 65px;
    height: 65px;
  }

  ${media.laptop} {
    width: 53px;
    height: 53px;
  }
`

export const CardPaginationWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: .5vw;
`

export const CardPageClick = styled.button`
  background: none;
	color: #584A9F;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
  font-size: ${props=> props.theme.heading2.fontSize};

  &:disabled{
      color: #C5C5C5;
      cursor: not-allowed;
  }
`