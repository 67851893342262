import { React, Component } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Patent from "pages/Patent/Patent";
import { Matches } from "pages/Match";
import { Detail } from "pages/Detail";
import { AuthProvider } from "contexts/AuthContext";
import LogInForm from "pages/Auth/LoginForm";
import PrivateRoute from "services/Utils/PrivateRoute";

class App extends Component {

    render() {
        return (
            <AuthProvider>
                <Routes>
                    <Route path="/login" element={<LogInForm />} />
                    <Route path="/tech-transfer">
                        <Route element={<PrivateRoute><Patent /></PrivateRoute>} index />
                        <Route path=":issuedNumber" element = {<PrivateRoute><Matches /></PrivateRoute>} />
                        <Route path=":issuedNumber/:organization" element = {<PrivateRoute><Detail /></PrivateRoute>} />
                    </Route>
                    <Route path="*" element={<Navigate to="/tech-transfer" replace={true} />}></Route>
                </Routes>
            </AuthProvider>
        )
    }
}

export default App;