import { createSlice } from '@reduxjs/toolkit';
import { getOptions } from 'store/Requests/options'; 


const defaultState = {
    options: {
        state: [],
        size: [],
        industryGroup: [],
        funding: []
    }
};

const options = createSlice({
    name: 'option/match',
    initialState: defaultState,
    reducers: {
        updateOptions: (state, action) => {
            state.options[action.payload.optionKey] = action.payload.optionValue;
        },

        clearOptions: (state, action) => {
            state.options[action.payload.key] = "";
        }
    },
    extraReducers(builder, dispatch) {
        builder.addCase(getOptions.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getOptions.fulfilled, (state, action) => {
            state.isLoading = false;
            const { results, filterName } = action.payload;
            state.options[filterName] = results[filterName];
        });
        builder.addCase(getOptions.rejected, (state, action) => {
            state.isLoading = false;
            state.error = action.payload;
        });
    }
})


export const { updateOptions, clearOptions } = options.actions;

export default options.reducer;