import Footer from "../Footer/footer";
import { LayoutProvider } from "../Default/style";

import { 
    GreetingsArea, 
    InfoArea, 
    LogInContainer, 
    GreetingsContainer,
    GreetingsHeading,
    GreetingsSubHeading,
} from "./style.auth";
import Loader from "../Loader/loader";

const Layout = (props) => {

    return (
        <LayoutProvider style={{ gap: '0' }}>
            <Loader isLoaderDisplay={props.isLoaderDisplay} />
            <LogInContainer>
                <GreetingsArea>
                    <GreetingsContainer>
                        <div style={{ display: 'flex', gap: '5px' }}>
                            <GreetingsHeading>
                                Welcome to <span style={{ fontWeight: "400"}}>MRDC WISE-R</span>
                            </GreetingsHeading>
                        </div>
                        <GreetingsSubHeading>
                            An AI life science platform that aggregates, analyzes & visualizes data to provide limitless dynamic insight from any functional perspective and level of detail
                        </GreetingsSubHeading>
                    </GreetingsContainer>
                </GreetingsArea>
                <InfoArea>
                    { props.children }
                </InfoArea>
            </LogInContainer>
            <Footer 
                relative={props.footerRelative}
                showSingleLine={props.isDeviceMobile} />
        </LayoutProvider>
    );
};

export default Layout;
