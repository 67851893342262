
import { useState, useEffect } from "react";
import { filterActions } from "store/Match/filterReducer";
import { Pagination, Loader, TopScrollBtn } from "components";
import { CardContainer, HomePage } from "./style.match";
import { MODE, PATH, PORT } from "services/api.js";
import { useAuth } from "contexts/AuthContext";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from "react-router";
import { getOptions } from 'store/Requests/options';
import { getEncryptObj } from "utils/encrypt";

import OrganizationCard from "./components/OrganizationCard";
import { ContentContainer } from "pages/style";
import { Layout } from "layout";
import { MATCH_PLACEHOLDER as HEADER_PLACEHOLDER } from 'services/Constants/constant';


export default function Match() {

    const [ data, setData ] = useState([]);
    const [ numOfPages, setNumOfPages ] = useState(0);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ loading, setLoading ] = useState(false);
    const [ searchText, setSearchText ] = useState("");
    const { currentUser, userLogOut } = useAuth();

    const filtersOptions = useSelector((store) => store.matchOptions.options);
    const filtersSelected = useSelector((store) => store.matchFilter.filters);
    const dispatch = useDispatch();

    const { issuedNumber } = useParams();

    // --------- Request Logic ------------

    // request new data when on a new page
    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [currentPage]);

    // request new data when new filter is applied or searching is entered
    useEffect(() => {
        setCurrentPage(1);
        setLoading(true);
        fetchData();
    }, [filtersSelected, searchText]);

    // Modify description length after every time new results are loaded
    useEffect(() => {
        if (data.length > 0) updateCardsDescriptionMaxLines();
    }, [data]);

    const fetchData = async () => {
        try {
            let filtersData = JSON.parse(JSON.stringify(filtersSelected));
            filtersData["search"] = searchText;
            filtersData["issuedNum"] = issuedNumber;
            const dataString = JSON.stringify({
                filters: filtersData,
                pageNum: currentPage,
            });
            const encryptObj = await getEncryptObj(dataString);
            const rawResponse = await fetch(
                `${MODE}://${PATH}:${PORT}/v1/matches?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`,
                {
                    method: "GET",
                    headers: {
                        Authorization: `${ currentUser }`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            );
    
            if (!rawResponse.ok && rawResponse.status === 403) {
                console.log(rawResponse.status);
                userLogOut();
            }
    
            const results = await rawResponse.json();
            // console.log(results)

            setNumOfPages(results.totalPageCount);
            setData(results.matchedOrganizations);
            setLoading(false);
        } catch (err) {
            console.error(`Error Occurred before fetch: ${err.message}`);
        }
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }

    const paginate = (number) => {
        setCurrentPage(number);
        scrollTop();
    }

    // --------- Filter Functions ------------
    const applyFilter = async (key, value) => {
        dispatch(filterActions.changeFilter({ key: key, value: value }));
    }

    const removeFilter = async (key) => {
        dispatch(filterActions.deselectFilter({ key: key }));
    }

    const resetFilter = async () => {
        dispatch(filterActions.clearFilter());
    }

    const fetchOptions = async (filterName) => {
        let filters = JSON.parse(JSON.stringify(filtersSelected));
        filters[filterName] = "";
        let filtersData = JSON.parse(JSON.stringify(filters));
        filtersData["issuedNum"] = issuedNumber;
        filtersData["search"] = searchText;
        const dataString = JSON.stringify({
            filters: filtersData,
            pageNum: 1,
        });
        // dispatch to redux thunk to handle request-sending
        dispatch(getOptions({
            url: `${MODE}://${PATH}:${PORT}/v1/matches/`,
            dataString: dataString,
            currentUser: currentUser, 
            filterName: filterName
        })).then(rawResponse => {
            if (rawResponse.type === "request/patent/rejected"){ 
                console.error("ERROR Occurred in options request\nerror message: ", rawResponse.meta);
                if (rawResponse.payload === 403) userLogOut(); // handle user account expiration
            }
        });
    }

    return (
        <HomePage >
            <Loader isVisible={loading}/>

            <Layout
                title={"WISE-R Matches"}
                footerRelative={data.length > 0}
                placeHolder={HEADER_PLACEHOLDER}
                searchCallback={(newValue) => setSearchText(newValue)}
                filtersOptions={filtersOptions}
                filtersSelected={filtersSelected}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                resetFilter={resetFilter}
                fetchOptions={fetchOptions}
            >
                <ContentContainer>
                    <CardContainer>
                        { data.length > 0  
                            ? data.map((item, index) => OrganizationCard(item, index)) 
                            : (
                                <h1 style={{color: '#827988', width: '100%', textAlign: 'center'}}>
                                    No data Present at the moment
                                </h1>
                            ) }
                    </CardContainer>

                    <Pagination
                        numberOfPages={numOfPages}
                        currentPage={currentPage}
                        paginate={paginate}
                    />

                    <TopScrollBtn 
                        label={"To Top"} 
                        handleOnClick={scrollTop} 
                        style={{ marginBottom: "-6vh" }} 
                    />
                </ContentContainer>
            </Layout>

        </HomePage>
    );
}

//---------Render Helper Functions----------

const calcCompanyNameHeadingLineCount = (headingRef) => {
    const headingHeight= +headingRef.offsetHeight;
    const defaultFontSize = 30;
    const headingLineHeight = +(headingRef.style?.lineHeight.replace('em','') * headingRef.style.fontSize.replace('rem','')) * defaultFontSize;
    
    const lineCount = Math.floor(headingHeight / headingLineHeight);
    return lineCount;
}

const updateCardsDescriptionMaxLines = () => {
    const cardHeadings = document.querySelectorAll('[id^="Company Name"]');
    const cardContents = document.querySelectorAll('[id^="Card Content"]');
    for( let i=0; i<cardHeadings.length; i++) {
        const maxLines = calcCompanyNameHeadingLineCount(cardHeadings[i]) === 1 ? 5 : 7;
        cardContents[i].style.maxHeight = (1.2 * maxLines) + "em";
        cardContents[i].style.webkitLineClamp = maxLines;
    }
}