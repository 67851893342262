//------------Layout----------------------
export const PATENT_PLACEHOLDER = `Search by Title or Keyword`;
export const MATCH_PLACEHOLDER = `Search by Organization name or Keyword`;
export const LINK = `mailto:hansilla.alaigh@globalactionalliance.net?subject=Share Additional Data`;
export const LINK_M = `mailto:hansilla.alaigh@globalactionalliance.net`

//------------Clinical Trails----------------
export const CLINICAL_TRAIL_FILTER_TAG = "CLINICAL_TRIALS";

export const FILTER_DELAY = 0; // in millie-seconds, times 1000 to convert to seconds

//------------- MAP-DATA-SAMPLE-----------------
/* NOTE: Note that if you are trying out react-simple-maps with coordinates found via Google search, you need to make sure they are in the right order ([lon, lat]). Google by default returns coordinates as [lat, lon], while d3-geo specifies them as [lon, lat]. */

export const MAP_DATA_1 = {
  isPiviCountrySelected: false,
  mapData: [
      {
          countryData: [
              {
                "countryName": "united states",
                "countryCode_ISO_A2": "us",
                "countryCode_ISO_A3": "", "value": 981
              },
              {
                "countryName": "no data",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 356
              },
              {
                "countryName": "germany",
                "countryCode_ISO_A2": "de",
                "countryCode_ISO_A3": "", "value": 340
              },
              {
                "countryName": "romania",
                "countryCode_ISO_A2": "ro",
                "countryCode_ISO_A3": "", "value": 323
              },
              {
                "countryName": "united kingdom",
                "countryCode_ISO_A2": "gb",
                "countryCode_ISO_A3": "", "value": 205
              },
              {
                "countryName": "belgium",
                "countryCode_ISO_A2": "be",
                "countryCode_ISO_A3": "", "value": 197
              },
              {
                "countryName": "japan",
                "countryCode_ISO_A2": "jp",
                "countryCode_ISO_A3": "", "value": 196
              },
              {
                "countryName": "canada",
                "countryCode_ISO_A2": "ca",
                "countryCode_ISO_A3": "", "value": 194
              },
              {
                "countryName": "australia",
                "countryCode_ISO_A2": "au",
                "countryCode_ISO_A3": "", "value": 176
              },
              {
                "countryName": "france",
                "countryCode_ISO_A2": "fr",
                "countryCode_ISO_A3": "", "value": 171
              },
              {
                "countryName": "spain",
                "countryCode_ISO_A2": "es",
                "countryCode_ISO_A3": "", "value": 140
              },
              {
                "countryName": "china",
                "countryCode_ISO_A2": "cn",
                "countryCode_ISO_A3": "", "value": 134
              },
              {
                "countryName": "india",
                "countryCode_ISO_A2": "in",
                "countryCode_ISO_A3": "", "value": 134
              },
              {
                "countryName": "italy",
                "countryCode_ISO_A2": "it",
                "countryCode_ISO_A3": "", "value": 113
              },
              {
                "countryName": "finland",
                "countryCode_ISO_A2": "fi",
                "countryCode_ISO_A3": "", "value": 106
              },
              {
                "countryName": "estonia",
                "countryCode_ISO_A2": "ee",
                "countryCode_ISO_A3": "", "value": 100
              },
              {
                "countryName": "thailand",
                "countryCode_ISO_A2": "th",
                "countryCode_ISO_A3": "", "value": 89
              },
              {
                "countryName": "netherlands",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 86
              },
              {
                "countryName": "russian federation",
                "countryCode_ISO_A2": "ru",
                "countryCode_ISO_A3": "", "value": 74
              },
              {
                "countryName": "south africa",
                "countryCode_ISO_A2": "za",
                "countryCode_ISO_A3": "", "value": 72
              },
              {
                "countryName": "hungary",
                "countryCode_ISO_A2": "hu",
                "countryCode_ISO_A3": "", "value": 72
              },
              {
                "countryName": "poland",
                "countryCode_ISO_A2": "pl",
                "countryCode_ISO_A3": "", "value": 71
              },
              {
                "countryName": "brazil",
                "countryCode_ISO_A2": "br",
                "countryCode_ISO_A3": "", "value": 71
              },
              {
                "countryName": "austria",
                "countryCode_ISO_A2": "at",
                "countryCode_ISO_A3": "", "value": 67
              },
              {
                "countryName": "mexico",
                "countryCode_ISO_A2": "mx",
                "countryCode_ISO_A3": "", "value": 60
              },
              {
                "countryName": "korea, republic of",
                "countryCode_ISO_A2": "kr",
                "countryCode_ISO_A3": "", "value": 66
              },
              {
                "countryName": "philippines",
                "countryCode_ISO_A2": "ph",
                "countryCode_ISO_A3": "", "value": 58
              },
              {
                "countryName": "sweden",
                "countryCode_ISO_A2": "se",
                "countryCode_ISO_A3": "", "value": 56
              },
              {
                "countryName": "taiwan",
                "countryCode_ISO_A2": "tw",
                "countryCode_ISO_A3": "", "value": 55
              },
              {
                "countryName": "bulgaria",
                "countryCode_ISO_A2": "bg",
                "countryCode_ISO_A3": "", "value": 54
              },
              {
                "countryName": "new zealand",
                "countryCode_ISO_A2": "nz",
                "countryCode_ISO_A3": "", "value": 52
              },
              {
                "countryName": "israel",
                "countryCode_ISO_A2": "il",
                "countryCode_ISO_A3": "", "value": 50
              },
              {
                "countryName": "lithuania",
                "countryCode_ISO_A2": "lt",
                "countryCode_ISO_A3": "", "value": 47
              },
              {
                "countryName": "latvia",
                "countryCode_ISO_A2": "lv",
                "countryCode_ISO_A3": "", "value": 44
              },
              {
                "countryName": "czechia",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 44
              },
              {
                "countryName": "argentina",
                "countryCode_ISO_A2": "ar",
                "countryCode_ISO_A3": "", "value": 41
              },
              {
                "countryName": "slovenia",
                "countryCode_ISO_A2": "si",
                "countryCode_ISO_A3": "", "value": 40
              },
              {
                "countryName": "switzerland",
                "countryCode_ISO_A2": "ch",
                "countryCode_ISO_A3": "", "value": 38
              },
              {
                "countryName": "hong kong",
                "countryCode_ISO_A2": "hk",
                "countryCode_ISO_A3": "", "value": 37
              },
              {
                "countryName": "denmark",
                "countryCode_ISO_A2": "dk",
                "countryCode_ISO_A3": "", "value": 37
              },
              {
                "countryName": "england",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 35
              },
              {
                "countryName": "singapore",
                "countryCode_ISO_A2": "sg",
                "countryCode_ISO_A3": "", "value": 34
              },
              {
                "countryName": "czech republic",
                "countryCode_ISO_A2": "cz",
                "countryCode_ISO_A3": "", "value": 32
              },
              {
                "countryName": "chile",
                "countryCode_ISO_A2": "cl",
                "countryCode_ISO_A3": "", "value": 31
              },
              {
                "countryName": "turkey",
                "countryCode_ISO_A2": "tr",
                "countryCode_ISO_A3": "", "value": 31
              },
              {
                "countryName": "ukraine",
                "countryCode_ISO_A2": "ua",
                "countryCode_ISO_A3": "", "value": 25
              },
              {
                "countryName": "greece",
                "countryCode_ISO_A2": "gr",
                "countryCode_ISO_A3": "", "value": 24
              },
              {
                "countryName": "slovakia",
                "countryCode_ISO_A2": "sk",
                "countryCode_ISO_A3": "", "value": 24
              },
              {
                "countryName": "peru",
                "countryCode_ISO_A2": "pe",
                "countryCode_ISO_A3": "", "value": 22
              },
              {
                "countryName": "bangladesh",
                "countryCode_ISO_A2": "bd",
                "countryCode_ISO_A3": "", "value": 22
              },
              {
                "countryName": "vietnam",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 27
              },
              {
                "countryName": "norway",
                "countryCode_ISO_A2": "no",
                "countryCode_ISO_A3": "", "value": 21
              },
              {
                "countryName": "colombia",
                "countryCode_ISO_A2": "co",
                "countryCode_ISO_A3": "", "value": 20
              },
              {
                "countryName": "dominican republic",
                "countryCode_ISO_A2": "do",
                "countryCode_ISO_A3": "", "value": 18
              },
              {
                "countryName": "puerto rico",
                "countryCode_ISO_A2": "pr",
                "countryCode_ISO_A3": "", "value": 16
              },
              {
                "countryName": "malaysia",
                "countryCode_ISO_A2": "my",
                "countryCode_ISO_A3": "", "value": 15
              },
              {
                "countryName": "honduras",
                "countryCode_ISO_A2": "hn",
                "countryCode_ISO_A3": "", "value": 13
              },
              {
                "countryName": "serbia",
                "countryCode_ISO_A2": "rs",
                "countryCode_ISO_A3": "", "value": 12
              },
              {
                "countryName": "lebanon",
                "countryCode_ISO_A2": "lb",
                "countryCode_ISO_A3": "", "value": 12
              },
              {
                "countryName": "panama",
                "countryCode_ISO_A2": "pa",
                "countryCode_ISO_A3": "", "value": 11
              },
              {
                "countryName": "guatemala",
                "countryCode_ISO_A2": "gt",
                "countryCode_ISO_A3": "", "value": 6
              },
              {
                "countryName": "croatia",
                "countryCode_ISO_A2": "hr",
                "countryCode_ISO_A3": "", "value": 6
              },
              {
                "countryName": "costa rica",
                "countryCode_ISO_A2": "cr",
                "countryCode_ISO_A3": "", "value": 6
              },
              {
                "countryName": "ireland",
                "countryCode_ISO_A2": "ie",
                "countryCode_ISO_A3": "", "value": 5
              },
              {
                "countryName": "kazakhstan",
                "countryCode_ISO_A2": "kz",
                "countryCode_ISO_A3": "", "value": 5
              },
              {
                "countryName": "saudi arabia",
                "countryCode_ISO_A2": "sa",
                "countryCode_ISO_A3": "", "value": 5
              },
              {
                "countryName": "pakistan",
                "countryCode_ISO_A2": "pk",
                "countryCode_ISO_A3": "", "value": 5
              },
              {
                "countryName": "indonesia",
                "countryCode_ISO_A2": "id",
                "countryCode_ISO_A3": "", "value": 4
              },
              {
                "countryName": "uganda",
                "countryCode_ISO_A2": "ug",
                "countryCode_ISO_A3": "", "value": 4
              },
              {
                "countryName": "iran, islamic republic of",
                "countryCode_ISO_A2": "ir",
                "countryCode_ISO_A3": "", "value": 4
              },
              {
                "countryName": "egypt",
                "countryCode_ISO_A2": "eg",
                "countryCode_ISO_A3": "", "value": 4
              },
              {
                "countryName": "kenya",
                "countryCode_ISO_A2": "ke",
                "countryCode_ISO_A3": "", "value": 4
              },
              {
                "countryName": "portugal",
                "countryCode_ISO_A2": "pt",
                "countryCode_ISO_A3": "", "value": 4
              },
              {
                "countryName": "senegal",
                "countryCode_ISO_A2": "sn",
                "countryCode_ISO_A3": "", "value": 3
              },
              {
                "countryName": "mozambique",
                "countryCode_ISO_A2": "mz",
                "countryCode_ISO_A3": "", "value": 3
              },
              {
                "countryName": "belarus",
                "countryCode_ISO_A2": "by",
                "countryCode_ISO_A3": "", "value": 3
              },
              {
                "countryName": "wales",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 3
              },
              {
                "countryName": "georgia",
                "countryCode_ISO_A2": "ge",
                "countryCode_ISO_A3": "", "value": 3
              },
              {
                "countryName": "el salvador",
                "countryCode_ISO_A2": "sv",
                "countryCode_ISO_A3": "", "value": 3
              },
              {
                "countryName": "united kindgdom",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 3
              },
              {
                "countryName": "none",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 3
              },
              {
                "countryName": "nigeria",
                "countryCode_ISO_A2": "ng",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "south korea",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "yemen",
                "countryCode_ISO_A2": "ye",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "scotland",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "paraguar",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "tunisia",
                "countryCode_ISO_A2": "tn",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "sudan",
                "countryCode_ISO_A2": "sd",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "zambia",
                "countryCode_ISO_A2": "zm",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "nepal",
                "countryCode_ISO_A2": "np",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "cameroon",
                "countryCode_ISO_A2": "cm",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "malasya",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "united arab emirates",
                "countryCode_ISO_A2": "ae",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "bosnia and herzegovina",
                "countryCode_ISO_A2": "ba",
                "countryCode_ISO_A3": "", "value": 2
              },
              {
                "countryName": "monaco",
                "countryCode_ISO_A2": "mc",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "the gambia",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "saint kitts and nevis",
                "countryCode_ISO_A2": "kn",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "gambia",
                "countryCode_ISO_A2": "gm",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "uzbekistan",
                "countryCode_ISO_A2": "uz",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "latovia",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "japan asia(except japan)",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "armenia",
                "countryCode_ISO_A2": "am",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "moldova",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "japan australia",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "ecuador",
                "countryCode_ISO_A2": "ec",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "lebano",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "paraguay",
                "countryCode_ISO_A2": "py",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "mali",
                "countryCode_ISO_A2": "ml",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "hungaria",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "korea, democratic people's republic of",
                "countryCode_ISO_A2": "kp",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "malawi",
                "countryCode_ISO_A2": "mw",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "the netherlands",
                "countryCode_ISO_A2": "nl",
                "countryCode_ISO_A3": "", "value": 1
              },
              {
                "countryName": "asia(except japan)",
                "countryCode_ISO_A2": "",
                "countryCode_ISO_A3": "", "value": 1
              }
          ],
          minValue: 1, 
          maxValue: 981,
          zoomCoordinates: [0,0] /* [75,9.6] */
      }
  ]
}

export const MAP_DATA_2 = {
  isPiviCountrySelected: true,
  mapData: [
      {
        countryData: [
              { countryName : "Uganda", countryCode_ISO_A2 : "ug", countryCode_ISO_A3 : "uga", value: 110, isMainCountry: true},
              { countryName: "Democratic Republic of the Congo", countryCode_ISO_A2: "cd", countryCode_ISO_A3: 'cod', value: 237 },
              { countryName: "Kenya", countryCode_ISO_A2: "ke", countryCode_ISO_A3: "ken", value: 728 },
              { countryName: "South Sudan", countryCode_ISO_A2: "ss", countryCode_ISO_A3: "ssd", value: 213 },
              { countryName: "Tanzania", countryCode_ISO_A2: "tz", countryCode_ISO_A3: "tza", value: 10 },
              { countryName: "Rwanda", countryCode_ISO_A2: "rw", countryCode_ISO_A3: "rwa", value: 98 },
          ],
          minValue: 10, 
          maxValue: 728,
          zoomCoordinates: [32.2903, 1.3733]
      },
      {
        countryData: [
              {countryName: "italy", countryCode_ISO_A2: "it", countryCode_ISO_A3 : "ita", value: 30, isMainCountry: true },
              {countryName: "Austria", countryCode_ISO_A2: "at", countryCode_ISO_A3 : "aut", value: 41 },
              {countryName: "France", countryCode_ISO_A2: "fr", countryCode_ISO_A3 : "fra", value: 192 },
              {countryName: "Slovenia", countryCode_ISO_A2: "si", countryCode_ISO_A3 : "svn", value: 981 },
              {countryName: "Switzerland", countryCode_ISO_A2: "ch", countryCode_ISO_A3 : "che", value: 341 },
          ],
          minValue: 30, 
          maxValue: 981,
          zoomCoordinates: [12.5674, 41.8719]        
        }
    ]
  }

export const tempByYearData = [
    {
        "1998": 20,
        "1999": 10,
        "2001": 10,
        "2003": 10,
        "2004": 10,
        "2005": 110,
        "2006": 240,
        "2007": 250,
        "2008": 370,
        "2009": 620,
        "2010": 440,
        "2011": 290,
        "2012": 360,
        "2013": 520,
        "2014": 680,
        "2015": 620,
        "2016": 860,
        "2017": 150,
        "2018": 170,
        "2019": 160,
        "2020": 190,
        "2021": 154,
        "2022": 188,
        "2023": 140,
        "2024": 10,
        "null": 196
    },
    {
        "1998": 2,
        "1999": 15,
        "2001": 16,
        "2003": 1,
        "2004": 17,
        "2005": 11,
        "2006": 243,
        "2007": 25,
        "2008": 37,
        "2009": 62,
        "2010": 44,
        "2011": 29,
        "2012": 36,
        "2013": 52,
        "2014": 68,
        "2015": 62,
        "2016": 86,
        "2017": 12,
        "2018": 124,
        "2019": 122,
        "2020": 124,
        "2021": 124,
        "2022": 111,
        "2023": 14,
        "2024": 1,
        "null": 190
    }
]

export const tempBySponsorTypeData = {
  sponsorTypes: [ "Academic", "Government", "Private", "Hospital", "Non-Profit", "N/A" ],
  trialsData: [
    {
        phase: "Phase 1",
        trialsCount: [ 60, 50, 20, 40, 30, 50 ]
    },
    {
        phase: "Phase 2",
        trialsCount: [ 50, 45, 37, 23, 18, 29 ]
    },
    {
        phase: "Phase 3",
        trialsCount: [ 32, 38, 28, 16, 13, 17 ]
    },
    {
        phase: "Phase 4",
        trialsCount: [ 28, 22, 19, 13, 10, 12 ]
    },
]
}

export const byPhaseColorArr = ['#A6C9D7', '#659BB6', '#468AAB', '#33708D', '#255166'];
export const byAgeColorArr = ["#A8B97C", "#B4C788", "#D9E8B2", "#E7F0D0", "#E1F2DD"];
export const bySampleSizeColorArr = ["#437E8D", "#73B8C9", "#84BAC5", "#B5E5F0", "#CAEEF8", "#D8F0F5"]
export const byYearColorArr = ["#164050", "#659BB6", "#468AAB", "#33708D", "#255166"]
export const bySponsorTypeStackedColorArr = [ "#194D61", "#335f70", "#4c717f", "#66838e" ]

export const COLORS_MAP_LEGEND_1 = [ '#255166', '#33708D', '#659BB6', '#A6C9D7' ];
export const COLORS_MAP_LEGEND_2 = [ '#417B8A', '#BEDFCE' ];