import { createSlice } from '@reduxjs/toolkit';
import { getOptions } from 'store/Requests/options'; 

export const defaultState = {
    isLoading: false,
    error: null,
    data: [],
    options: {
        year: [['', true]],
        lab: [['', true]],
        inventor: [['', true]],
        category: [['', true]]
    }
};

export const options = createSlice({
    name: 'header/tabs',
    initialState: defaultState,
    reducers: {
        updateOptions: (state, action) => {
            state.options[action.payload.optionKey] = action.payload.optionValue;
        },
        clearOptions: (state, action) => {
            state.options[action.payload.key] = "";
        }
    },
    extraReducers(builder, dispatch) {
        builder.addCase(getOptions.pending, (state, action) => {
            state.isLoading = true;
        });
        builder.addCase(getOptions.fulfilled, (state, action) => {
            state.isLoading = false;
            const { results, filterName } = action.payload;
            state.options[filterName] = results[filterName] ;
        });
        builder.addCase(getOptions.rejected, (state, action) => {
            console.log(action.error);
            state.isLoading = false;
            state.error = action.error;
        });
    }
});

export const { updateOptions, clearOptions } = options.actions;
export default options.reducer;