import Header from "../Header/header";
import Footer from "../Footer/footer";
import Breadcrumb from "../Breadcrumb/breadcrumb";
import Loader from "../Loader/loader";
import { LINK } from "services/Constants/constant";
import {
    LayoutProvider,
    BodyContainer,
    BreadcrumbWrapper,
    TextButton,
    TitleContainer,
} from "./style";
import FiltersRow from "../Filters/FiltersRow";
import { SearchBar } from "components";

const Layout = (props) => {
    return (
        <LayoutProvider>
            <Loader isLoaderDisplay={false} />
            <Header
                isHeaderFixed={!props.isGraphSectionVisible}
                OnBackClickCallback={props.onBackClickCallback}
                searchInputCallback={props.handleSearchInput}
                searchSubmitCallback={props.handleSearchSubmit}
                searchFocusCallback={props.handleSearchBarFocusCallback}
            />
            <BodyContainer>
                <BreadcrumbWrapper>
                    <Breadcrumb />
                    <TextButton href={`${LINK}`}>Share Additional Data</TextButton>
                </BreadcrumbWrapper>
                <TitleContainer>{props.title}</TitleContainer>
                { props.searchCallback 
                    ? <SearchBar
                        width={"50vw"}
                        placeHolder={props.placeHolder}
                        submitCallback={props.searchCallback}
                    /> 
                    : null }
                { props.filtersSelected 
                    ? <FiltersRow
                        { ...props }
                    />
                    : null }
                {props.children}
            </BodyContainer>
            <Footer relative={props.footerRelative} />
        </LayoutProvider>
    );
};

export default Layout;
