import React, { Component } from "react";
import { FiltersContainer } from "./style";
import { ResetFilter } from "./style";
import Filter from "./Filter";
import SingleSelectionFilter from "./SingleSelectionFilter";
import { defaultState } from 'store/Match/filterReducer';
import { isEqual } from "lodash";


class FiltersRow extends Component {

    filtersPresentation = new Map([
        ["year", "Year"],
        ["lab", "Lab"],
        ["inventor", "Inventor(s)"],
        ["category", "Technological Groups"],
        ["state", "Location"],
        ["industryGroup", "Industry Groups"],
        ["funding", "Total Funding Amount"],
        ["size", "Employee Size"],
    ]);

    render() {
        // let filterList = JSON.parse(JSON.stringify(this.props.filterValues));
        let filterList = this.props.filtersSelected;

        return (
            <div style={{ height: "3.5vh", zIndex: "10", marginTop: '3vh'}}>
                <FiltersContainer>

                    {Object.entries(filterList).map((entry) => {
                        const filterName = entry[0], value = entry[1];
                        return (
                            <Filter
                                key={filterName}
                                filterLabel={this.filtersPresentation.get(filterName)}
                                filterName={filterName}
                                filterValue={value}
                                options={this.props.filtersOptions[filterName]}
                                clearTimeOutVaccine={this.props.clearTimeOutVaccine}
                                setTimeOutVaccine={this.props.setTimeOutVaccine}
                                ifSelected={this.props.ifSelected}
                                handleClickFilters={this.props.handleClickFilters}
                                { ...this.props }
                            >
                                {filterName}
                            </Filter>
                        )
                    })}
                    { 
                        !isEqual(defaultState.filters, filterList) &&
                            <ResetFilter onClick={this.props.resetFilter}>Clear all</ResetFilter>
                    }

                </FiltersContainer>
            </div>
        )
    };
};


export default FiltersRow;
