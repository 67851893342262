
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "contexts/AuthContext";
import { filterActions } from 'store/Patent/filterReducer';
import { getOptions } from 'store/Requests/options'; 
import { useDispatch, useSelector } from 'react-redux';
import { getEncryptObj } from "services/Utils/encrypt.js";
import { MODE, PATH, PORT } from "services/api.js";

import { Layout } from "layout";
import { PATENT_PLACEHOLDER as HEADER_PLACEHOLDER } from 'services/Constants/constant';
import { Pagination, Loader } from "components";
import { Table, Thead, Tbody, ThDiv, TdDiv, ViewResultButton, TableWrapper } from './style.patent';


export default function Patent() {
    const [ tableData, setTableData ] = useState([]);
    const [ numOfPages, setNumOfPages ] = useState(0);
    const [ currentPage, setCurrentPage ] = useState(1);
    const [ loading, setLoading ] = useState(false);
    const { currentUser, userLogOut } = useAuth();
    const [ searchText, setSearchText ] = useState("");

    const filtersOptions = useSelector((store) => store.patentOptions.options);
    const filtersSelected = useSelector((store) => store.patentFilter.filters);
    const dispatch = useDispatch();

    // --------- Request Logic ------------
    const fetchData = async () => {
        let filtersData = JSON.parse(JSON.stringify(filtersSelected));
        filtersData["search"] = searchText;
        const dataString = JSON.stringify({
            filters: filtersData,
            pageNum: currentPage,
        });
        const encryptObj = await getEncryptObj(dataString);
        const rawResponse = await fetch(
            `${MODE}://${PATH}:${PORT}/v1/?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`,
            {
                method: "GET",
                headers: {
                    Authorization: `${currentUser}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        );

        if (!rawResponse.ok && rawResponse.status === 403) {
            console.log(rawResponse.status);
            userLogOut();
        }

        const results = await rawResponse.json();
        // console.log(results)

        setNumOfPages(results.totalPageCount);
        setTableData(results.patents);
        setLoading(false)
    }

    // request new data when on a new page
    useEffect(() => {
        setLoading(true);
        fetchData();
    }, [currentPage]);

    // request new data when new filter is applied or searching is entered
    useEffect(() => {
        setCurrentPage(1);
        setLoading(true);
        fetchData();
    }, [filtersSelected, searchText]);

    const paginate = (number) => {
        setCurrentPage(number);

        // scroll to top of page when switching to new page
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    // --------- Filter Functions ------------
    const applyFilter = async (key, value) => {
        dispatch(filterActions.changeFilter({ key: key, value: value }));
    }

    const removeFilter = async (key) => {
        dispatch(filterActions.deselectFilter({ key: key }));
    }

    const resetFilter = async () => {
        dispatch(filterActions.clearFilter());
    }

    const fetchOptions = async (filterName) => {
        let filters = JSON.parse(JSON.stringify(filtersSelected));
        filters[filterName] = "";
        let filtersData = JSON.parse(JSON.stringify(filters));
        filtersData["search"] = searchText;
        const dataString = JSON.stringify({
            filters: filtersData,
            pageNum: 1,
        });
        // dispatch to redux thunk to handle request-sending
        dispatch(getOptions({
            url: `${MODE}://${PATH}:${PORT}/v1/`,
            dataString: dataString,
            currentUser: currentUser, 
            filterName: filterName
        }));
    }

    return (
        <>
            <Loader isVisible={loading}/>
            <Layout
                title={"Tech Transfer"}
                footerRelative={tableData.length > 0}
                placeHolder={HEADER_PLACEHOLDER}
                searchCallback={(newValue) => setSearchText(newValue)}
                filtersOptions={filtersOptions}
                filtersSelected={filtersSelected}
                applyFilter={applyFilter}
                removeFilter={removeFilter}
                fetchOptions={fetchOptions}
                resetFilter={resetFilter}
            >
                <TableWrapper>
                    <Table>
                        <Thead>
                            <tr>
                                <th>
                                    <ThDiv>
                                        <span>Published/Issued No.</span>
                                        <span >
                                            {/* <VscTriangleUp />
                                            <VscTriangleDown /> */}
                                        </span>
                                    </ThDiv>
                                </th>
                                <th>
                                    <ThDiv>
                                        <span>Published/Issued Date</span>
                                        <span >
                                            {/* <VscTriangleUp />
                                            <VscTriangleDown /> */}
                                        </span>
                                    </ThDiv>
                                </th>
                                <th style={{ width: "33%"}}>
                                    <ThDiv>
                                        <span>Title</span>
                                        <span >
                                            {/* <VscTriangleUp />
                                            <VscTriangleDown /> */}
                                        </span>
                                    </ThDiv>
                                </th>
                                <th>
                                    <ThDiv>
                                        <span>Lab</span>
                                        <span >
                                            {/* <VscTriangleUp />
                                            <VscTriangleDown /> */}
                                        </span>
                                    </ThDiv>
                                </th>
                                <th width={"140px"}>
                                    <ThDiv>
                                        <span>Attachments</span>
                                        <span >
                                            {/* <VscTriangleUp />
                                            <VscTriangleDown /> */}
                                        </span>
                                    </ThDiv>
                                </th>
                                <th>
                                    <ThDiv>
                                        <span>Inventors</span>
                                        <span >
                                            {/* <VscTriangleUp />
                                            <VscTriangleDown /> */}
                                        </span>
                                    </ThDiv>
                                </th>
                                <th>
                                    <ThDiv>
                                        <span>Technologies</span>
                                        <span >
                                            {/* <VscTriangleUp />
                                            <VscTriangleDown /> */}
                                        </span>
                                    </ThDiv>
                                </th>
                                <th width={"130px"}>
                                    <ThDiv>
                                        <span>WISE-R Match</span>
                                        <span >
                                            {/* <VscTriangleUp />
                                            <VscTriangleDown /> */}
                                        </span>
                                    </ThDiv>
                                </th>
                            </tr>
                        </Thead>
                        <Tbody>
                            <TableBody tableData={tableData}/>
                        </Tbody>
                    </Table>
                </TableWrapper>

                <Pagination
                    numberOfPages={numOfPages}
                    currentPage={currentPage}
                    paginate={paginate}
                />
            </Layout>
        </>
    )
}


const TableBody = ({tableData}) => {
    const navigate = useNavigate();

    return (
        tableData.length > 0 
            ? tableData.map((data, index) => {
                return (
                    <tr key={data._id}>
                        <td><TdDiv>{data["Issued_Number"]}</TdDiv></td>
                        <td><TdDiv>{data["Issued_Date"]}</TdDiv></td>
                        <td>{data["Title"]}</td>
                        <td><TdDiv>{data["Lab"]}</TdDiv></td>
                        <td>
                            <TdDiv>
                                <Link to={data["PDF_LINKS"]} target="_blank" style={{ color: '#4A6697' }}>
                                    View Source Here
                                </Link>
                            </TdDiv>
                        </td>
                        <td id='investors'><TdDiv>{data["Inventors"]}</TdDiv></td>
                        <td><TdDiv>{data["Categories"].join(", ")}</TdDiv></td>
                        <td style={{ whiteSpace: "nowrap" }}>
                            <TdDiv>
                                <ViewResultButton onClick={()=> navigate(`${data["Issued_Number"]}`)}>
                                    View Matches
                                </ViewResultButton>
                            </TdDiv>
                        </td>
                    </tr>
                );
            })
            : <></>
    );
}