import React, { useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css'
import { Tooltip } from "react-tooltip";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import { USA_COORDINATES } from 'services/Constants/coordinatesUSA';

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";


export default function Map({ state, city }) {

    const data = state 
        ? [{ name: state, color: "#233A60", values: [{ val: city ?? "" }] }]
        : null;

    const projectionConfig = state
        ? { scale: 2300, center: USA_COORDINATES[state] }
        : { scale: 1000, center: [-98, 38] }

    return (
        <>
            <ComposableMap 
                projection={"geoMercator"}
                width={1000}
                projectionConfig={projectionConfig}
            >
                <Geographies 
                    geography={geoUrl}
                >
                    {({ geographies }) => 
                        geographies.map(geo => {
                            let cur;
                            if (data) {
                                cur = data.find(s => s.name === geo.properties.name);
                            }
                            return (
                                <Geography
                                    key={geo.rsmKey}
                                    geography={geo}
                                    style={{
                                        default: {
                                            fill: `${cur ? cur.color :'#F0F0EF'}`,
                                            stroke: "#FFF"
                                        },
                                        hover: {
                                            fill: `${cur ? cur.color :'#F0F0EF'}`,
                                            stroke: "#FFF"
                                        },
                                        pressed: {
                                            fill: `${cur ? cur.color :'#F0F0EF'}`, 
                                            stroke: "#FFF"
                                        }
                                    }}
                                    className="my-anchor-element"
                                    data-tooltip-content={cur ? `${geo.properties.name}${cur ? `, ${cur.values[0].val}` : ''}` : null}
                                    data-tooltip-float={true}
                                    data-tooltip-variant="info"
                                    data-tooltip-offset={20}
                                />
                            );
                        })
                }
                </Geographies>
            </ComposableMap>
            <Tooltip
                anchorSelect=".my-anchor-element"
                place="bottom"
                style={{backgroundColor:"#FFFFFF", color:'#000000',boxShadow:"3px 12px 20px rgba(0, 0, 0, 0.25);", borderRadius:'4px', boxSizing:'border-box', maxWidth:"10vw", opacity:"1"}}
            />
        </>
    )
}