import { useState } from 'react';
import { useParams, useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom'

const withRouter = WrappedComponent => props => {
    const location = useLocation();
        const navigate = useNavigate();
        const params = useParams();

    return (
        <WrappedComponent
            {...props}
            router={{ location, navigate, params }}
        />
    );
};

export default withRouter;