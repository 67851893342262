import { createAsyncThunk } from '@reduxjs/toolkit';
import { PORT, PATH, MODE } from "services/api";
import { getEncryptObj } from "services/Utils/encrypt.js";


const getOptions = createAsyncThunk('request/patent', async ({ url, dataString, currentUser, filterName }, { rejectWithValue }) => {
    const encryptObj = await getEncryptObj(dataString);

    const response = await fetch(
        `${url}filter?iv=${encryptObj["iv"]}&encryptedData=${encryptObj["encryptedData"]}`,
        {
            method: "GET",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": `${currentUser}`
            }
        }).then(rawResponse => {
            if (rawResponse.ok) {
                return rawResponse.json();
            }
            throw rawResponse;
        })
        .then(response => {
            return { results: response, filterName: filterName };
        })
        .catch(error => {
            // TODO: udpate return with more meaningful error messages
            return rejectWithValue(error.status)
        });

    return response;
});

export { getOptions };