import React from 'react';
// import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from 'styled-components'
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store/index';

import GlobalStyle from'./globalStyle.js';
import { theme } from 'assets/static';
import './globalStyle';

import * as ReactDOM from 'react-dom/client';


const container = document.getElementById('root');

const root = ReactDOM.createRoot(container);

root.render(
    <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ ThemeProvider>
)



// ReactDOM.render (
//     <ThemeProvider theme={theme}>
//         <GlobalStyle />
//         <Provider store={store}>
//             <App />
//         </Provider>
//     </ ThemeProvider>,
//     document.getElementById('root')
// );


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
