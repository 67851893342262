import React, { Component } from "react";
import {
    FilterWrapper,
    FilterLabel,
    FilterIcon,
    OptionsMenu,
    OptionItem
} from "./style";
import FilterInput from "./FilterInput";
import { connect } from "react-redux";
import { filterActions } from 'store/Match/filterReducer';

class SingleSelectionFilter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterState: false,
        };

        this.handleFilterSelect = this.handleFilterSelect.bind(this);
    };

    handleFilterSelect = (key, value) => {
        this.setState(() => ({
            filterState: false
        }), () => {
            this.props.changeFilter({ key: key, value: value});
        });
    };

    changeFilterState = () => {
        // this.setState((prevState) => ({
        //     filterState: !prevState.filterState,
        // }), () => {
        //     if (this.state.filterState === false) {
        //         //Over Here We will call SetTimeOut only if there is a change in filterState;
        //         if (this.props.ifSelected)
        //             this.props.setTimeOutVaccine();
        //         console.log('True')
        //     } else {
        //         this.props.clearTimeOutVaccine();
        //     }
        // });
        this.setState((prevState) => ({
            filterState: !prevState.filterState,
        }));
    };

    close = () => {
        this.setState(() => ({
            filterState: false,
        }));
    };

    componentDidUpdate(){
        const { filterState } = this.state;

        setTimeout(() => {
            if (filterState) {
                window.addEventListener('click', this.close);
            } else {
                window.removeEventListener('click', this.close);
            }
        }, 0)
    };

    render = () => {
        const { filterLabel, filterName, filterValue, options } = this.props;

        const ifOpen = this.state.filterState;
        return (
            <FilterWrapper ifOpen={ifOpen} ifApplied={true}>
                <FilterLabel onClick={this.changeFilterState}>
                <FilterInput 
                    filterLabel={filterLabel}
                    filterValue={filterValue}
                />
                    <FilterIcon ifOpen={ifOpen} ifApplied={true}/>
                </FilterLabel>
                <OptionsMenu>
                    {this.getFilterOptions(filterName, options)}
                </OptionsMenu>
            </FilterWrapper>
        )
    };

    getFilterOptions = (name, options) => {
        return options.map((entry) => {
            const [ option, selectable ] = entry;
            const ifSelectable = Boolean(selectable);
            return (
                <OptionItem
                    key={option}
                    onClick={
                        ifSelectable ?
                            () => this.handleFilterSelect(name, option)
                            : undefined
                    }
                    ifApplied={true}
                    ifSelectable={ifSelectable}
                >
                    <option value={option}>
                        {option}
                    </option>
                </OptionItem>
            )
        });
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj))
    };
};

export default connect(null, mapDispatchToProps)(SingleSelectionFilter);
