import React from 'react';
import { Tags } from "components";
import { 
    CardContentWrapper,
    CardContent,
    OrganisationCard,
    OtherTagsWrapper,
    LocationWrapper,
    CardTitle,
    Type,
    Description
} from "../style.match";
import LearnMore from "./LearnMore";

export default function OrganizationCard(item, index) {
    return (
        <OrganisationCard key={item.Company_Name}>
            <CardContentWrapper>
                <CardTitle id={"Company Name- " + index}>
                    {item["Company_Name"]}
                </CardTitle>
                <Type>{item["Industry_Groups"].join(", ") ?? <span>&nbsp;</span>}</Type>
                <Description>
                    <CardContent
                        id={"Card Content- " + index}>
                        {item["Description"]}
                    </CardContent>
                </Description>
            </CardContentWrapper>
            <LocationWrapper>
                { getLocationTags(item["Location"]) }
            </LocationWrapper>
            <OtherTagsWrapper>
                { getOtherTags(item) }
            </OtherTagsWrapper>
            <div style={{ width: '100%', display: 'flex', gap: '.5vw'}}>
                <LearnMore item={item} />
            </div>
        </OrganisationCard>
    )
}

const getLocationTags = (locations) => {
    if (locations == null) return null;

    let locationString = "";
    if (locations.city) locationString += locations.city;
    if (locations.state) locationString += `, ${locations.state}`;

    return <Tags
            data={locationString}
            tagsKey={"Location"}
        />;
}

const getOtherTags = (item) => {
    const tagsList = getOtherTagsList(item);
    return tagsList.map((tagItem, index) => {
        return <Tags key={index} data={tagItem[0]} tagsKey={tagItem[1]}/>;
    });
}

const getOtherTagsList = (item) => {
    let tagsList = [
        [item["Company_Size"], "Employee Size"]
    ];
    let cardSpace = 3;
    if (cardSpace > 0 && item["Total_Funding"]) {
        tagsList.push([`${item["Total_Funding"]}`, "Total Funding (In USD)"]);
        cardSpace -= 1;
    }
    return tagsList;
}