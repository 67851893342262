import React, { Component } from "react";
import {
    FilterWrapper,
    FilterLabel,
    FilterIcon,
    OptionsMenu,
    OptionItem
} from "./style";
import FilterInput from "./FilterInput";
import { connect } from "react-redux";
import { filterActions } from 'store/Patent/filterReducer';
import { updateOptions } from 'store/Patent/optionReducer';
import { BarLoader } from "react-spinners";
import { truncate } from "utils/Function/string";
import { BsTriangleFill } from "react-icons/bs";


const optionRemoveMap = new Map([
    // Patent Filters
    ["year", "All Start Years"],
    ["lab", "All Labs"],
    ["inventor", "All Inventors"],
    ["category", "All Categories"],

    // Matches Filters
    ["state", "All Locations"],
    ["city", "All Cities"],
    ["size", "All Company Sizes"],
    ["industryGroup", "All Industry Groups"],
    ["funding", "All Funding Ranges"]
]);


class Filter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            filterState: false,
            loading: false,
        };

        this.handleFilterSelect = this.handleFilterSelect.bind(this);
        this.handleFilterDeselect = this.handleFilterDeselect.bind(this);
    };

    closeMenu = () => {
        this.setState(() => ({
            filterState: false,
        }));
    };

    // To select a filter
    handleFilterSelect = (key, value) => {
        this.closeMenu();
        const { applyFilter } = this.props;
        applyFilter(key, value);
    };

    // To de-select a filter
    handleFilterDeselect = (key) => {
        this.closeMenu();
        const { removeFilter } = this.props;
        removeFilter(key);
    };

    // Open or Close the filter menu
    changeFilterState = () => {
        this.setState((prevState) => ({
            filterState: !prevState.filterState,
        }),
        () => {
            // if the filter has just been opened, fetch its options
            if (this.state.filterState) this.getOptions();
        });
    };

    getOptions = () => {
        this.setState(
            () => ({
                loading: true,
            }), async () => {
                try {
                    await this.props.fetchOptions(this.props.filterName);
                } catch (err) {
                    console.error(`Error Occurred during fetch: ${err.message}`);
                } finally {
                    this.setState({
                        loading: false
                    });
                }
        });
    };

    componentDidUpdate() {
        setTimeout(() => {
            if (this.state.filterState) {
                window.addEventListener('click', this.closeMenu);
            } else {
                window.removeEventListener('click', this.closeMenu);
            }
        }, 0);
    };

    componentWillUnmount() {
        window.removeEventListener('click', this.closeMenu);
    }



    render = () => {
        const { filterLabel, filterName, filterValue, options } = this.props;

        const ifOpen = this.state.filterState, ifApplied = filterValue !== "";

        return (
            <FilterWrapper ifOpen={ifOpen} ifApplied={ifApplied}>
                <FilterLabel onClick={this.changeFilterState}>
                    <FilterInput 
                        filterLabel={filterLabel}
                        filterValue={filterValue}
                    />
                    <FilterIcon ifOpen={ifOpen}>
                        <BsTriangleFill />
                    </FilterIcon>
                </FilterLabel>
                <OptionsMenu>
                    { this.state.loading 
                        ? <BarLoader
                                color="#381650"
                                height={5}
                                speedMultiplier={0.8}
                                width={80}
                                cssOverride={{ margin: "2vh 0px 2vh 1vw" }}
                            />
                        :  this.getFilterMenu(filterName, options, ifApplied)
                    }
                </OptionsMenu>
            </FilterWrapper>
            
        )
    };

    getFilterMenu = (filterName, options, ifApplied) => {
        return (
            <>
                { (ifApplied) && <OptionItem
                    key="cancel-option"
                    onClick={() => this.handleFilterDeselect(filterName)}
                    ifApplied={ifApplied}
                    ifSelectable={true}
                >
                    <option style={{height: "10px", width: "100px"}}>{optionRemoveMap.get(filterName)}</option>
                </OptionItem> }
                { this.getFilterOptions(filterName, options, ifApplied) }
            </>
        )
    }

    getFilterOptions = (name, options, ifApplied) => {
        return options.map((entry) => {
            const [ option, selectable ] = entry;
            const ifSelectable = Boolean(selectable);
            return (
                <OptionItem
                    key={option}
                    onClick={
                        ifSelectable ?
                            () => this.handleFilterSelect(name, option)
                            : undefined
                    }
                    ifApplied={ifApplied}
                    ifSelectable={ifSelectable}
                > 
                    {/* <option value={option}>
                        {option}
                    </option> */}
                    { option }
                </OptionItem>
            )
        });
    };
};

const mapStateToProps = state => {
    return {
        filters: state.patentFilter.filters,
        filterOptions: state.patentOptions.options
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateOptions: (obj) => dispatch(updateOptions(obj)),
        changeFilter: (obj) => dispatch(filterActions.changeFilter(obj)),
        deselectFilter:(obj) => dispatch(filterActions.deselectFilter(obj)),
        clearOptions: (obj) => dispatch(updateOptions.clearOptions(obj)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Filter);
