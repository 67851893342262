import React from "react";
import { HeaderContainer, LogoComponent } from "../Default/style";
import { HeaderBackBtn, UserNameBtn } from "components";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "contexts/AuthContext";
import Logo from "assets/image/wiser-logo.png";

const Header = (props) => {

    const { currentUser } = useAuth();
    const navigate = useNavigate();

    return (
        <HeaderContainer isHeaderFixed={props.isHeaderFixed}>
            <HeaderBackBtn OnClickCallback={() => navigate(-1)} />
            <LogoComponent style={{color: "white"}}>
                <img id="org-logo" src={Logo} alt="Logo" />
                <h3 style={{color: "white"}}>MRDC WISE-R<sup>SM</sup></h3>
            </LogoComponent>
            <UserNameBtn userName={currentUser.name ?? "Test User"} />
        </HeaderContainer>
    );
}

export default Header;